import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../AuthContext';
import "./LoginForm.css";
import Cookies from "js-cookie";

const LoginForm = ({handleShowRegistrationForm}) => {
    const { setUserIsLoggedIn, setUser , host, port } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const verifyToken = async (token) => {
        try {
            const response = await axios.post(`https://${host}:${port}/verify-token`, { token });

            if (response.status === 200) {
                console.log('Token verified: User is logged in.');
                setUserIsLoggedIn(true);
                const loggedInUser = response.data.decoded.user; // Utilisez les données décodées du token pour l'utilisateur
                console.log(response.data.decoded)
                console.log(Cookies.get('logToken'))
                setUser(loggedInUser);
            } else {
                console.log('Token verification failed: User is not logged in.');
                setUserIsLoggedIn(false); // Utilisateur non connecté
                // Effacer le token du localStorage si la vérification échoue
                Cookies.remove('logToken');
            }
        } catch (error) {
            console.error('Error verifying token:', error);
        }
    };
    const handleLogin = async () => {
        try {
            const response = await axios.post(`https://${host}:${port}/login`, {
                email,
                password,
            });

            if (response.status === 200) {
                console.log('Login: Success');

                const logToken = response.data.token; // Récupérer le token JWT du serveur
                Cookies.set('logToken', logToken);

                verifyToken(logToken); // Vérifier le token après la connexion

                // Rediriger l'utilisateur vers une autre page ou effectuer d'autres actions nécessaires
            } else {
                const errorData = response.data; // Récupérer les détails de l'erreur
                setError(errorData.error); // Mettre à jour l'état d'erreur
            }
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };


    return (
        <div className="login-component">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-4">
                        <div className="card bg-transparent"  style={{border:"none"}}>
                            <div className="card-body mt-5">
                                <h2 className="card-title text-center text-white mb-4"
                                    style={{fontSize: "30px", textShadow: "2px 2px 4px #000000"}}>Login</h2>
                                {error && <div className="alert alert-danger">{error}</div>}
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    handleLogin();
                                }}>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label text-white" style={{fontSize: "18px", textShadow: "2px 2px 4px #000000"}}>E-mail :</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label text-white" style={{fontSize: "18px", textShadow: "2px 2px 4px #000000"}}>Password :</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3 text-center text-white">
                                        <button type="submit" className="btn btn-primary w-100">Log In</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-link text-white" style={{fontSize: "20px", textShadow: "2px 2px 4px #000000"}} onClick={handleShowRegistrationForm}>
                                No Account? Register
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default LoginForm;
