import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./Ability.css"


const AbilityModal = ({ ability, onClose, abilities }) => {

    const matchingAbility = abilities.find((item) => item.name === ability);

    return (
        <div className="ability-component">
            <div className="grey card modal-centered" style={{backgroundColor: "#6B6450"}}>
                <div className="card-header grey ability-header" style={{backgroundColor: "#6B6450"}}>
                    <div className="modal-title d-flex align-items-center justify-content-center">
                        <img
                            src={`/assets/icons/abilities/${ability}.png`}
                            style={{ filter: 'url(#shadow)' }}
                            alt={ability}
                            className="ability-icon-modal me-2"
                            width="60"
                        />
                        <span>{ability}</span>
                    </div>
                </div>
                <div className="card-body" style={{backgroundColor: "#6B6450"}}>
                    <div>
                        <p>{matchingAbility.description}</p>
                    </div>
                </div>
                <Button variant="secondary" onClick={onClose} style={{background: "tan"}}>
                    Close
                </Button>
            </div>
        </div>
    );
};

export default AbilityModal;
