import React, {useMemo, useState} from 'react';
import "./DetailsContent.css";
import { useSwipeable } from 'react-swipeable';
import Button from "react-bootstrap/Button";


const DetailsContent = ({ cardDetails, abilities, onClose }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => alert("left"),
        onSwipedRight: () => alert("right"),
    });

    const slides = useMemo(() => {
        const availableSlides = [];
        if (cardDetails.summonerSkills && cardDetails.summonerSkills.length > 0) {
            availableSlides.push('Skills');
        }
        if (cardDetails.baseEffects && cardDetails.baseEffects.length > 0) {
            availableSlides.push('Base Effects');
        }
        if (cardDetails.actions && cardDetails.actions.length > 0) {
            availableSlides.push('Actions');
        }
        return availableSlides;
    }, [cardDetails]); // Dépendances pour useMemo

    const updateSlide = (direction) => {
        setCurrentSlide((prev) => {
            const newIndex = prev + direction;
            if (newIndex < 0) {
                return slides.length - 1; // Retour à la fin si on dépasse le début
            } else if (newIndex >= slides.length) {
                return 0; // Retour au début si on dépasse la fin
            }
            return newIndex;
        });
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => updateSlide(1),
        onSwipedRight: () => updateSlide(-1),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    // Rendu conditionnel basé sur le slide actuel et les slides disponibles
    const renderCurrentSlide = () => {
        const slide = slides[currentSlide];
        switch (slide) {
            case 'Skills':
                return (
                    <div className="detailled_skill">
                        {cardDetails.summonerSkills.length > 0 && (
                            <div className="skills">
                                <h3 className="text-center mb-4 mt-2">SKILL</h3>
                                {cardDetails.summonerSkills.map((skill, index) => (
                                    <p key={index}>
                                        {index + 1} ~ {skill.skill}
                                        <br/>
                                        {skill.skillDetail}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                );
            case 'Base Effects':
                return (
                    <div className="base_effect">
                        {cardDetails.baseEffects.length > 0 && (
                            <>
                                <h3 className="text-center">BASE EFFECT :</h3>
                                <br/>
                                {cardDetails.baseEffects.map((effect, index) => (
                                    <div key={index} className="effect">
                                        <span className="effect-title">
                                            {effect.effect && effect.effect.length > 0 ? (
                                                <span dangerouslySetInnerHTML={{__html: `~ ${effect.effect}`}}/>
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                        <span className="effect-detail"> {effect.effectDetail}</span>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                );
            case 'Actions':
                return (
                    <div className="action">
                        {cardDetails.actions.map((action, index) => (
                            <div key={index} className="action">
                                <h3 className="text-center">ACTION :</h3>
                                <br/>
                                {action.tossOfCoin && action.tossOfCoin.length > 0 && (
                                    <div
                                        className="toss-coin-action d-flex align-items-center justify-content-center">
                                        <img className="toss_coin mx-2" src="assets/icons/coins/coin_flip.png"
                                             alt="" width="30px"/>
                                        <h3 className="text-center">Toss of Coin </h3>
                                        <img className="toss_coin mx-2" src="assets/icons/coins/coin_flip.png"
                                             alt="" width="30px"/>
                                    </div>
                                )}
                                {action.tossOfCoin.map((coin, index) => (
                                    <div key={index} className="coin-action">
                                        <div className="toss-action">
                                            {coin.success && coin.success.description && (
                                                <p>
                                                    ~ {coin.cost > 0 && (coin.cost)}
                                                    <img className="toss_coin"
                                                         src="assets/icons/coins/coin_flip.png" alt=""
                                                         width="30px"/>
                                                    : Success: {coin.success.description} {coin.success.detail}
                                                </p>
                                            )}
                                            {coin.fail && coin.fail.description && (
                                                <p>
                                                    - Fail: {coin.fail.description} {coin.fail.detail}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ))}

                                {action.directPurchase && action.directPurchase.length > 0 && (
                                    <div
                                        className="direct-purchase-action d-flex align-items-center justify-content-center">
                                        <img className="direct-purchase mx-2" src="assets/icons/coins/pay_coin.png"
                                             alt="" width="30px"/>
                                        <h2 className="text-center">Direct purchase :</h2>
                                        <img className="direct-purchase mx-2" src="assets/icons/coins/pay_coin.png"
                                             alt="" width="30px"/>
                                    </div>
                                )}
                                <br/>
                                {action.directPurchase.map((purchase, index) => (
                                    <div key={index} className="direct-purchase-action">
                                        {purchase.cost > 100 ? (
                                            <p>
                                                ~ {(purchase.cost > 0 && (Math.floor(purchase.cost / 100) % 10))}
                                                <img className="ether-cost" src="assets/icons/ether.png" alt=""
                                                     width="30px"/>: {purchase.action}
                                                <br/>
                                                {purchase.actionDetail}
                                            </p>
                                        ) : (
                                            <p>
                                                ~ {(purchase.cost > 0 && (purchase.cost))} <img
                                                className="coin-icon" src="assets/icons/coins/pay_coin.png" alt=""
                                                width="30px"/>: {purchase.action}
                                                <br/>
                                                {purchase.actionDetail}
                                            </p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="details-content-component">
            <div className="card details-content align-items-center" {...handlers}>
                {renderCurrentSlide()}
                <div className="stats d-flex justify-content-center mt-4">
                    {cardDetails.types[0] === 'Creature' && (
                        <>
                            <img className="sword" src="assets/icons/sword.png" alt="Sword Icon" width="20px"/>
                            <h3 style={{marginRight: '15px'}}>{cardDetails.attack === undefined ? "*" : cardDetails.attack}</h3>
                            <img className="shield" src="assets/icons/shield.png" alt="Shield Icon" width="20px"/>
                            <h3 style={{marginRight: '15px'}}>{cardDetails.defence === undefined ? "*" : cardDetails.defence}</h3>

                        </>
                    )}
                    {cardDetails.types[0] === 'Door' && (
                        <>
                            <img className="sword" src="assets/icons/sword.png" alt="Sword Icon" width="20px"/>
                            <h3 style={{marginRight: '15px'}}>{cardDetails.attack === undefined ? "*" : cardDetails.attack}</h3>
                            <img className="shield" src="assets/icons/shield.png" alt="Shield Icon" width="20px"/>
                            <h3 style={{marginRight: '15px'}}>{cardDetails.defence === undefined ? "*" : cardDetails.defence}</h3>

                        </>
                    )}
                    {cardDetails.types[0] === 'Summoner' && (
                        <>
                        {cardDetails.healingPoints}.HP
                        </>
                    )}
                </div>
                <br/>
                <div className="flavorText d-flex justify-content-center">
                    {cardDetails.flavorText && (
                        <>
                            ~ {cardDetails.flavorText} ~
                        </>
                    )}
                </div>
                <br/>
                <div className="d-flex justify-content-center">
                    <p className="text-center">
                        {cardDetails.cardNumber} {cardDetails.edition}
                        <br/>
                        Illus. {cardDetails.illustrator.join(', ')}
                    </p>
                </div>
                <Button variant="secondary" onClick={onClose} style={{background: "#6b6450", width: "100%"}}>
                    Close
                </Button>
            </div>
        </div>
    );
};

export default DetailsContent;
