import React, { useState } from 'react';
import './Search.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";

const Search = ({ onSearch, toggleFilter }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        onSearch(value);
    };


    return (
        <div className="search-container d-flex justify-content-around align-items-center">
            <input
                type="text"
                placeholder="Rechercher par nom..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="form-control grey"
            />
            <button className="btn" onClick={toggleFilter} id="filter-button">
                <FontAwesomeIcon className="fa-filter" icon={faFilter} />
            </button>
        </div>
    );
};

export default Search;
