import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import axios from 'axios';
import DeckBuilder from '../DeckBuilder/DeckBuilder.v2';
import { useAuth } from '../../AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DeckList.css";
import DeckView from "../Index/Cards/DeckView";
import Swal from 'sweetalert2';
import {
    faEllipsisV,
    faFileExport,
    faFileAlt,
    faCopy,
    faTrash,
    faPlusCircle,
    faInfoCircle,
    faEye,
    faEyeSlash,
    faEdit, faCircleArrowLeft
} from '@fortawesome/free-solid-svg-icons';

const DeckList = forwardRef(({ cardsData, gameData }, ref) => {

    useImperativeHandle(ref, () => ({
        resetDeckList: () => {
            setSelectedDeckData(null); // Réinitialisez l'état lié au deck chargé
            // Vous pouvez également réinitialiser d'autres états au besoin
        },
    }));

    const { user, deckCreated, setDeckCreated , host, port } = useAuth();
    const [abilities, setAbilities] = useState(cardsData.abilities);
    const [showDeckBuilder, setShowDeckBuilder] = useState(false);
    const [userDecks, setUserDecks] = useState([]);
    const [selectedDeckData, setSelectedDeckData] = useState(null);
    const [isModalDeckViewVisible, setIsModalDeckViewVisible] = useState(false);
    const [isDeckBuilderVisible, setIsDeckBuilderVisible] = useState(false);
    const [deckIsPublic, setDeckIsPublic] = useState({});
    const [errorDeckIndex, setErrorDeckIndex] = useState(null);
    const [isKebabMenuOpen, setIsKebabMenuOpen] = useState({});

    const SvgFilters = () => (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ height: 0, width: 0, position: 'absolute' }}>
            <defs>
                <filter id="shadow" height="130%">
                    <feDropShadow dx="2" dy="2" stdDeviation="2" floodColor="black" />
                </filter>
            </defs>
        </svg>
    );

    const toggleKebabMenu = (index) => {
        setIsKebabMenuOpen(prevState => ({
            ...prevState, // Copie de l'état précédent
            [index]: !prevState[index] // Bascule la valeur pour l'index donné
        }));
    };

    const handleShowError = (index) => {
        setErrorDeckIndex(errorDeckIndex === index ? null : index);
    };

    const togglePublicStatus = async (index) => {
        try {
            const deckId = userDecks[index]._id;
            const response = await axios.put(`https://${host}:${port}/deck/update/${deckId}`, {
                isPublic: !userDecks[index].isPublic,
            });

            if (response.status === 200) {
                setDeckIsPublic((prevState) => ({
                    ...prevState,
                    [deckId]: !prevState[deckId]
                }));
            } else {
                console.error('Failed to update isPublic');
            }
        } catch (error) {
            console.error('Error updating isPublic:', error);
        }
    };

    const openDeckBuilder = () => {
        setIsDeckBuilderVisible(true);
        setIsModalDeckViewVisible(false);
    };

    const handleAddDeck = () => {
        setShowDeckBuilder(true);
        setDeckCreated(false);
    };

    useEffect(() => {
        const fetchUserDecks = async () => {
            try {
                const response = await axios.get(`https://${host}:${port}/user/view/${user._id}`);
                const fetchedDecks = response.data.decks;
                setUserDecks(fetchedDecks);

                // Set the public status for each deck
                const publicStatus = {};
                fetchedDecks.forEach(deck => {
                    publicStatus[deck._id] = deck.isPublic;
                });
                setDeckIsPublic(publicStatus);

            } catch (error) {
                console.error('Error fetching user decks:', error);
            }
        };

        fetchUserDecks();
    }, [user._id, deckCreated]); // Update on component mount and when deckCreated changes

    useEffect(() => {
        if (deckCreated) {
            setShowDeckBuilder(false);
        }
    }, [deckCreated]);

    const loadDeck = async (deckId) => {
        try {
            const response = await axios.get(`https://${host}:${port}/deck/view/${deckId}`);
            setSelectedDeckData(response.data);
            setIsModalDeckViewVisible(true);
        } catch (error) {
            console.error('Error fetching deck details:', error);
        }
    };

    const exportDeck = async (deckId, format) => {
        const url = `https://webapp.risecardgame.com:${port}/export/${format}/deck/${deckId}`;
        try {
            const response = await fetch(url, { method: 'GET' });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            // Gérer la réponse, par exemple en téléchargeant le fichier
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `deck_${deckId}.${format}`;
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Failed to export deck:', error);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                showFlashMessage("TTS export Number Copied");
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const showFlashMessage = (message) => {
        const deckImageDiv = document.querySelector('.deck-image');
        const errorDeckDiv = deckImageDiv ? deckImageDiv.querySelector('.error-deck') : null;

        if (errorDeckDiv) {
            const messageElement = document.createElement("div");
            messageElement.textContent = message;
            messageElement.style.backgroundColor = "lightgreen";
            messageElement.style.padding = "10px";
            messageElement.style.borderRadius = "5px";
            messageElement.style.color = "white";
            messageElement.style.textAlign = "center";
            messageElement.style.marginTop = "10px";

            errorDeckDiv.appendChild(messageElement);

            setTimeout(() => {
                errorDeckDiv.removeChild(messageElement);
            }, 3000);
        } else {
            console.error("Element to display flash message not found.");
        }
    };

    const deleteDeck = async (deckId) => {
        const result = await Swal.fire({
            title: 'You want really delete this deck ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        });

        if (!result.isConfirmed) {
            return;
        }

        if (!deckId) {
            console.error('No deck ID provided for deletion');
            return;
        }

        const url = `https://${host}:${port}/deck/delete/${deckId}`;

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.ok) {
                console.log(`Deck with ID ${deckId} has been deleted.`);
                setUserDecks(prevDecks => prevDecks.filter(deck => deck._id !== deckId)); // Remove deck from local state
            } else {
                const errorBody = await response.text();
                console.error('Failed to delete deck. Status:', response.status, 'Response:', errorBody);
            }
        } catch (error) {
            console.error('Error occurred while deleting deck:', error);
        }
    };

    return (
        <div className="decklist-component mt-4">
            {isDeckBuilderVisible && (
                <div className="dashboard-deckbuilder">
                    <FontAwesomeIcon className="return-2-decks" icon={faCircleArrowLeft} style={{fontSize: '2rem', color: 'tan', filter: 'url(#shadow)', marginLeft:"calc(50% - 16px)"}} onClick={() => setIsDeckBuilderVisible(false)}/>
                    <DeckBuilder cardsData={cardsData} deck={selectedDeckData.deck.cards}
                                 deckId={selectedDeckData.deck._id}
                                 deckN={selectedDeckData.deck.name} abilities={abilities}
                    />
                </div>
            )}
            { isModalDeckViewVisible ? (
                <div className="deck-view d-flex flex-column align-items-center justify-content-center">
                    <div onClick={openDeckBuilder} style={{cursor:"pointer"}}>
                        <SvgFilters />
                        <FontAwesomeIcon icon={faEdit} style={{fontSize: '2rem', color: 'tan', filter: 'url(#shadow)'}}/>
                    </div>
                    <div className="content">
                        <DeckView cardsData={selectedDeckData.deck} gameData={gameData} cAbilities={abilities}
                                  setisModalDeckViewVisible={true} setIsDeckBuilderVisible={false}/>
                    </div>
                </div>
            ) : (
                <div className="add-deck-section">
                    {showDeckBuilder ? (
                        <div className="dashboard-create-deck">
                            <DeckBuilder cardsData={cardsData} userDecks={userDecks} deck={[]} abilities={abilities}/>
                        </div>
                    ) : (
                        <div className="decks-section ">
                            {!isDeckBuilderVisible && !isModalDeckViewVisible && !showDeckBuilder && (
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card col-12 col-lg-12 mb-4"
                                                 style={{backgroundColor: "grey", color: "white"}}>
                                                <div
                                                    className="card-body add-deck d-flex flex-column align-items-center justify-content-center">
                                                    <div style={{cursor: 'pointer'}} onClick={handleAddDeck}>
                                                        <FontAwesomeIcon icon={faPlusCircle} style={{
                                                            fontSize: '5rem',
                                                            color: 'lightgrey'
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {userDecks.map((deck, index) => (
                                            <div key={deck._id}
                                                 className={`card deck-container col-12 col-md-6 text-center mb-4 `}>
                                                <div
                                                    className="card-header deck-title d-flex justify-content-between align-items-center">
                                                    <h3 className="card-title deck-name">{deck.name}</h3>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        position: 'relative'
                                                    }}>
                                                        <>
                                                            <FontAwesomeIcon icon={faEllipsisV} style={{
                                                                fontSize: '2rem',
                                                                color: "white",
                                                                cursor: 'pointer'
                                                            }} onClick={() => toggleKebabMenu(index)}/>
                                                            {isKebabMenuOpen[index] && (
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    top: 50,
                                                                    right: 0,
                                                                    backgroundColor: '#6b6450',
                                                                    color: 'lightgray',
                                                                    borderRadius: '5px',
                                                                    padding: '10px',
                                                                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    gap: '10px'
                                                                }}>
                                                                    <div onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        exportDeck(deck._id, 'json');
                                                                    }} title="Export as JSON">
                                                                        <FontAwesomeIcon
                                                                            icon={faFileExport}
                                                                            style={{
                                                                                marginRight: '5px',
                                                                                fontSize: '2rem',
                                                                                cursor: "pointer"
                                                                            }}/>
                                                                    </div>
                                                                    <div onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        exportDeck(deck._id, 'txt');
                                                                    }} title="Export as TXT">
                                                                        <FontAwesomeIcon
                                                                            icon={faFileAlt}
                                                                            style={{
                                                                                marginRight: '5px',
                                                                                fontSize: '2rem',
                                                                                cursor: "pointer"
                                                                            }}/>
                                                                    </div>
                                                                    <div onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        copyToClipboard(deck._id);
                                                                    }} title="Copy TTS Export Number">
                                                                        <FontAwesomeIcon icon={faCopy}
                                                                                         style={{
                                                                                             marginRight: '5px',
                                                                                             fontSize: '2rem',
                                                                                             cursor: "pointer"
                                                                                         }}/>
                                                                    </div>
                                                                    <div onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        deleteDeck(deck._id);
                                                                    }} title="Delete Deck"
                                                                         style={{color: 'lightcoral'}}>
                                                                        <FontAwesomeIcon icon={faTrash}
                                                                                         style={{
                                                                                             marginRight: '5px',
                                                                                             color: 'lightcoral',
                                                                                             fontSize: '2rem',
                                                                                             cursor: "pointer"
                                                                                         }}/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                        <FontAwesomeIcon className="set-public"
                                                                         icon={deckIsPublic[deck._id] ? faEye : faEyeSlash}
                                                                         style={{
                                                                             fontSize: '2rem',
                                                                             color: deckIsPublic[deck._id] ? 'lightgrey' : 'lightcoral',
                                                                             marginLeft: '15px',
                                                                             cursor: "pointer"
                                                                         }} onClick={(e) => {
                                                            e.stopPropagation();
                                                            togglePublicStatus(index);
                                                        }}
                                                                         title={deckIsPublic[deck._id] ? "Set deck as private" : "Set deck as public"}/>
                                                    </div>
                                                </div>
                                                <div className="card-body deck-image" onClick={(e) => {
                                                    e.preventDefault();
                                                    loadDeck(deck._id);
                                                }}>
                                                    <div className="error-deck">
                                                        {deck.haveError && errorDeckIndex === index && (
                                                            <div className="message alert alert-danger">
                                                                {deck.listOfError.map((error, i) => (
                                                                    <span key={i}>| {error} |</span>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
});

export default DeckList;
