import React, {useEffect, useRef, useState} from 'react';
import CardDetails from "../Cardetails/CardDetails";
import Search from "../Search/Search";
import Filter from "../Search/Filter";
import { useAuth } from "../../AuthContext";
import {Dropdown} from "react-bootstrap";
import {BrowserView, MobileView} from "react-device-detect";
import {faPlusCircle, faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CardSelection from "./CardSelection";
import axios from "axios";
import "./DeckBuilder.css"


const Deckbuilderv2 = ({ cardsData, deckView, cAbilities, isCollection, toggleCardSelection, saveCollection, selectedCards, deck, deckId, deckN }) => {
    const { deckCreated, setDeckCreated } = useAuth();
    const [deckName, setDeckName] = useState('');
    const [cardQuantities, setCardQuantities] = useState([]);
    const [isDeckNameModified, setIsDeckNameModified] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');
    const [flashType, setFlashType] = useState('');




    useEffect(() => {
        if (deck.length > 0) {
            setCardQuantities(deck);
            updateCardQuantities(deck);
        }
    }, [deck]);

    const handleIncrement = (cardId, cardName) => {
        const cardQuantity = cardQuantities.find(item => item.id === cardId)?.quantity;
        const cardRank = cardsData.cards.find(card => card._id === cardId)?.rank;

        let excess = 0;
        if (cardQuantity >= cardRank) {
            excess = cardQuantity + 1 - cardRank;
        }

        setCardQuantities(prevQuantities => {
            const existingCardIndex = prevQuantities.findIndex(item => item.id === cardId);
            if (existingCardIndex !== -1) {
                const updatedQuantities = [...prevQuantities];
                updatedQuantities[existingCardIndex].quantity += 1;
                return updatedQuantities;
            } else {
                return [...prevQuantities, { id: cardId, quantity: 1, name: cardName, rank: cardRank }];
            }
        });

        if (excess > 0) {
            // Store the excess card details in a separate array for further use
            const excessCard = { id: cardId, excess: excess };
            // You can manage how to handle this array, whether to store in state or elsewhere

            console.log(`Card with ID ${excessCard.id} has ${excessCard.excess} excess.`);
        }

    };

    const handleDecrement = (cardId, cardName) => {
        setCardQuantities((prevQuantities) => {
            const updatedQuantities = prevQuantities.map(item => {
                if (item.id === cardId && item.quantity > 1) {
                    return { ...item, quantity: item.quantity - 1 };
                }
                return item;
            });
            return updatedQuantities.filter(item => item.quantity > 0);
        });
    };


    const handleDelete = (cardId) => {
        setCardQuantities((prevQuantities) => {
            const updatedQuantities = prevQuantities.filter(item => item.id !== cardId);
            return updatedQuantities;
        });
    };



    const handleCreateDeck = async () => {
        setDeckCreated(true);
        console.log(deckCreated)
        try {
            const response = await axios.post(`https://${host}:${port}/deck/create`, {
                creator: user._id,
                name: deckName,
                cards: JSON.stringify(cardQuantities),
                creationDate: Date.now(),
                public: false
            });

            console.log('Deck created successfully:', response.data);

        } catch (error) {
            console.error('Error creating deck:', error);
            console.log('Server error response:', error.response);
        }
    };

    const handleUpdateDeck = async () => {
        if (deck) {
            try {
                // Remplacez l'URL de l'API par celle de la mise à jour du deck
                const response = await axios.put(`https://${host}:${port}/deck/update/${deckId}`, {
                    // Remplacez les données avec les nouvelles informations de mise à jour
                    creator: user._id,
                    name: deckName,
                    cards: JSON.stringify(cardQuantities),
                    creationDate: Date.now(),
                    public: false
                });

                console.log('Deck updated successfully:', response.data);
                setFlashMessage('Deck updated successfully!');
                setFlashType('success');
                setTimeout(() => {
                    setFlashMessage('');
                }, 5000);
            } catch (error) {
                console.error('Error updating deck:', error);
                console.log('Server error response:', error.response);
                setFlashMessage('Error updating the deck. Please try again.');
                setFlashType('error');
                setTimeout(() => {
                    setFlashMessage('');
                }, 5000);
            }
        }
    };

    const updateCardQuantities = (deck) => {
        if (deck && deck.length > 0) {
            // Créer un tableau d'objets contenant l'id, la quantité et le nom des cartes du deck
            const updatedCardQuantities = deck.map((deckItem) => ({
                id: deckItem.card._id,
                quantity: deckItem.quantity,
                name: deckItem.card.name,
                rank: deckItem.card.rank
            }));

            // Mettre à jour cardQuantities avec les nouvelles données
            setCardQuantities(updatedCardQuantities);
        }
    };

    const [selectedCard, setSelectedCard] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCards, setFilteredCards] = useState(cardsData && cardsData.cards ? cardsData.cards : []);
    const [abilities, setAbilities] = useState(cardsData.abilities);
    const [dAbilities, setDAbilities] = useState(cAbilities);
    const { showFilter, setShowFilter, host, port, user } = useAuth();
    const [appliedFilters, setAppliedFilters] = useState({});
    const filtersParam = { "editions": cardsData.editions, "types": cardsData.types, "subtypes": cardsData.subtypes, "abilities": cardsData.abilities, "rarities": cardsData.rarities }
    const [selectedEdition, setSelectedEdition] = useState('All');
    const [filters, setFilters] = useState({});const isFilterApplied = Object.keys(filters).length > 0;
    const barRef = useRef(null);
    const [maxButtons, setMaxButtons] = useState(0);
    const scrollRight = () => {
        document.querySelector('.edition-filter').scrollLeft += 100; // Scrolls by 100px; adjust as needed
    };


    const SvgFilters = () => (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ height: 0, width: 0, position: 'absolute' }}>
            <defs>
                <filter id="shadow" height="130%">
                    <feDropShadow dx="2" dy="2" stdDeviation="2" floodColor="black" />
                </filter>
            </defs>
        </svg>
    );

    // Fonction pour réinitialiser tous les filtres
    const resetFilters = () => {
        setFilters({});
    };





    const filterByEdition = (edition) => {
        setSelectedEdition(edition);
        const filters = { ...appliedFilters, edition: [edition] };
        applyFilters(filters, searchTerm);
    };

    const filterAll = () => {
        setSelectedEdition('All');
        const filters = { ...appliedFilters };
        delete filters.edition; // Supprimer le filtre par édition
        applyFilters(filters, searchTerm);
    };

    const applyFilters = (filters, searchTerm) => {
        setAppliedFilters(filters);

        // Filtrer les cartes en fonction des filtres appliqués
        let filtered = cardsData.cards;

        if (filters.searchTerm) {
            const searchText = filters.searchTerm.toLowerCase();
            filtered = filtered.filter((card) =>
                card.name.toLowerCase().includes(searchText) ||
                card.types.some((type) => type.toLowerCase() === searchText) ||
                card.edition.some((edition) => edition.toLowerCase() === searchText) ||
                card.subtypes.some((subtype) => subtype.toLowerCase() === searchText) ||
                card.summonerSkills.some((skill) => skill.skill.toLowerCase().includes(searchText)) ||
                card.baseEffects.some((effect) => effect.effect.toLowerCase().includes(searchText)) ||
                card.baseEffects.some((effect) => effect.effectDetail.toLowerCase().includes(searchText)) ||
                card.actions.some((action) => action.tossOfCoin.some((toss) => toss.success.description.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.tossOfCoin.some((toss) => toss.success.detail.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.directPurchase.some((purchase) => purchase.action.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.directPurchase.some((purchase) => purchase.actionDetail.toLowerCase().includes(searchText)))
            );
        }

        if (filters.type && Array.isArray(filters.type)) {
            const selectedTypeTerms = filters.type.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                const cardTypeTerms = card.types.map((type) => type.trim().toLowerCase());

                return selectedTypeTerms.some((selectedTerm) => {
                    if (selectedTerm === 'All') {
                        return true; // Inclure si "All" est sélectionné
                    }

                    if (selectedTerm.includes(' ')) {
                        // Si le terme du filtre contient des espaces
                        const filterTerms = selectedTerm.split(' ');

                        if (filterTerms.every((term) => cardTypeTerms.includes(term))) {
                            return true;
                        }
                    } else {
                        if (cardTypeTerms.includes(selectedTerm)) {
                            return true;
                        }
                    }

                    return false;
                });
            });
        }

        if (filters.edition && Array.isArray(filters.edition)) {
            const selectedTypeTerms = filters.edition.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.edition.some((edition) => {
                        const typeTerms = edition.trim().toLowerCase().split(' ');
                        return typeTerms.includes(selectedTerm);
                    });
                });
            });
        }

        if (filters.subtype && Array.isArray(filters.subtype)) {
            const selectedTypeTerms = filters.subtype.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.subtypes.some((type) => {
                        const typeTerms = type.trim().toLowerCase().split(' ');
                        return typeTerms.includes(selectedTerm);
                    });
                });
            });
        }

        if (filters.ability && Array.isArray(filters.ability)) {
            const selectedTypeTerms = filters.ability.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.abilities.some((type) => {
                        const typeTerms = type.trim().toLowerCase().split(' ');
                        return typeTerms.includes(selectedTerm);
                    });
                });
            });
        }

        if (filters.defense) {
            filtered = filtered.filter((card) => filters.defense === 'All' || card.defense === filters.defense);
        }

        if (filters.healingPoints) {
            filtered = filtered.filter((card) => filters.healingPoints === 'All' || card.healingPoints === filters.healingPoints);
        }

        if (filters.rarity && Array.isArray(filters.rarity)) {
            const selectedTypeTerms = filters.rarity.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.rarity.toLowerCase() === selectedTerm
                });
            });
        }

        setFilteredCards(filtered);
    };

    const showCardDetails = (cardId) => {
        const selectedCard = filteredCards.find((card) => card._id === cardId);
        setSelectedCard(selectedCard);
    };

    const hideCardDetails = () => {
        setSelectedCard(null);
    };

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);

        const filtered = cardsData.cards.filter((card) =>
            card.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCards(filtered);
    };

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    function setActive(selectedButton) {
        document.querySelectorAll('.edition-button').forEach(button => {
            button.classList.remove('active');
        });

        selectedButton.classList.add('active');
    }

    const renderEditionButtons = (editions) => {
        const firstThreeEditions = editions.slice(0, 2);

        if (editions.length <= 2) {
            return firstThreeEditions.map((edition, index) => (
                <>
                    <button
                        className={`edition-button btn ${selectedEdition === 'All' ? 'btn-secondary' : 'btn-outline-secondary'}`}
                        onClick={filterAll}
                    >
                        All
                    </button>
                    <button
                        key={index}
                        className={`edition-button btn ${selectedEdition === edition.name ? 'btn-secondary' : 'btn-outline-secondary'}`}
                        onClick={() => filterByEdition(edition.name)}
                    >
                        {edition.name}
                    </button>
                </>
            ));
        } else {
            const otherEditions = editions.slice(2);

            return (
                <div className="d-flex justify-content-around align-items-center">
                    <button
                        className="edition-button btn active mx-1 mx-lg-4"
                        onClick={(e) => {filterAll(); setActive(e.target)}}
                    >
                        All
                    </button>
                    {firstThreeEditions.map((edition, index) => (
                        <button
                            key={index}
                            className="edition-button btn mx-1 mx-lg-4"
                            onClick={(e) => { filterByEdition(edition.name); setActive(e.target) }}
                        >
                            {edition.name}
                        </button>
                    ))}
                    <Dropdown>
                        <Dropdown.Toggle className="edition-button mx-1">
                            Prestige
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {otherEditions.map((edition, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => filterByEdition(edition.name)}
                                >
                                    {edition.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {isCollection && (
                        <button onClick={saveCollection} className="btn edition-button ms-2" >Save</button>
                    )}
                </div>
            );
        }
    };


    const calculateMaxButtons = () => {
        if (barRef.current) {
            const containerWidth = barRef.current.offsetWidth;
            let totalButtonsWidth = 0;
            let maxButtonsCount = 0;

            const buttonElements = barRef.current.querySelectorAll('.edition-button');
            buttonElements.forEach(button => {
                totalButtonsWidth += button.offsetWidth +
                    parseInt(window.getComputedStyle(button).marginLeft, 10) +
                    parseInt(window.getComputedStyle(button).marginRight, 10);
            });

            buttonElements.forEach(button => {
                const buttonWidth = button.offsetWidth +
                    parseInt(window.getComputedStyle(button).marginLeft, 10) +
                    parseInt(window.getComputedStyle(button).marginRight, 10);

                if (totalButtonsWidth <= containerWidth) {
                    maxButtonsCount = buttonElements.length;

                } else if (totalButtonsWidth - buttonWidth < containerWidth) {
                    maxButtonsCount--;
                    totalButtonsWidth -= buttonWidth;
                }
            });

            setMaxButtons(maxButtonsCount);
        }
    };

    // Call calculateMaxButtons on mount and on window resize
    useEffect(() => {
        calculateMaxButtons();
        window.addEventListener('resize', calculateMaxButtons);
        return () => window.removeEventListener('resize', calculateMaxButtons);
    }, []);



    return (
        <div className="deckBuilder-component parent">
            <MobileView>
                {/* Main Content */}
                {showFilter ? (
                    <Filter filters={filtersParam} applyFilters={applyFilters} searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
                ) : (
                    <div className="container mt-4 card-index">
                        {/* Search Bar */}
                        <div className="top-list">
                            <div className="action-container">
                                <div className="create-deck d-flex justify-content-around align-items-center mb-2">
                                    <input
                                        className="deck-name form-control border-0 border-bottom border-primary bg-transparent input-lightgray w-50 "
                                        required
                                        type="text"
                                        placeholder="Deck Name"
                                        value={isDeckNameModified ? deckName : deckN}
                                        onChange={(e) => {
                                            setDeckName(e.target.value);
                                            setIsDeckNameModified(true);
                                        }}
                                    />
                                    <SvgFilters />
                                    {deck.length > 0 ?
                                        <FontAwesomeIcon icon={faSave} style={{fontSize: '2rem', color: 'tan', filter: 'url(#shadow)'}} onClick={handleUpdateDeck}/> :
                                        <FontAwesomeIcon icon={faSave} style={{fontSize: '2rem', color: 'tan', filter: 'url(#shadow)'}} onClick={handleCreateDeck}/>
                                    }
                                </div>
                                {flashMessage && <div className={`flash-message ${flashType}`}>{flashMessage}</div>}
                            </div>
                            <div className="mb-4 search-bar">
                                <Search onSearch={handleSearch} toggleFilter={toggleFilter}/>
                            </div>
                        </div>

                        <div className="row card-list-row">
                            {filteredCards.map((card) => (
                                <div key={card._id} className="col-4 col-md-4 col-lg-3 px-1 mb-4">
                                    <div key={card._id} className="card-container pl-1 pr-1">
                                        <div className="card-container-builder pl-1 pr-1 position-relative">
                                            <img
                                                key={card.id}
                                                className="card-img img-fluid mb-0"
                                                src={`/assets/cards/${card.edition}/EN/${card.cardNumber}_${card.name
                                                    .toLowerCase()
                                                    .replace(/ /g, '_')}_en_cut_100ppi_eng.png`}
                                                alt={card.name}
                                                onClick={() => setSelectedCard(card)}
                                            />
                                            {cardQuantities.some(item => item.id === card._id) ? (
                                                <CardSelection
                                                    cardId={card._id}
                                                    cardName={card.name}
                                                    cardRank={card.rank}
                                                    quantity={cardQuantities.find(item => item.id === card._id).quantity}
                                                    handleDelete={() => handleDelete(card._id)}
                                                    handleIncrement={() => handleIncrement(card._id, card.name)}
                                                    handleDecrement={() => handleDecrement(card._id)}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    style={{
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        bottom: '0',
                                                        left: '50%',
                                                        transform: 'translateX(-50%)',
                                                        fontSize: '2.5rem',
                                                        color: 'lightgrey'
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        handleIncrement(card._id, card.name);
                                                    }}
                                                />
                                            )}
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                )}
                {/* Render the CardDetails component when selectedCard is not null */}
                {selectedCard && (
                    <CardDetails cardDetails={selectedCard} abilities={abilities} onClose={() => setSelectedCard(null)}/>
                )}
            </MobileView>
            <BrowserView>

                <div className="parent container-fluid browser-view">
                    <div className="row">
                        <div className="col-md-4 col-lg-3 filter"
                             style={{position: "sticky", top: "0", height: "100vh", overflowY: "auto"}}>
                            <div className="action-container">
                                <div className="create-deck d-flex justify-content-around align-items-center mb-2">
                                    <input
                                        className="deck-name form-control border-0 border-bottom border-primary bg-transparent input-lightgray w-50 text-center"
                                        required
                                        type="text"
                                        placeholder="Deck Name"
                                        placeholderTextColor="#fff"
                                        value={isDeckNameModified ? deckName : deckN}
                                        onChange={(e) => {
                                            setDeckName(e.target.value);
                                            setIsDeckNameModified(true);
                                        }}
                                    />
                                    <SvgFilters/>
                                    {deck.length > 0 ?
                                        <FontAwesomeIcon icon={faSave}
                                                         style={{
                                                             fontSize: '2rem',
                                                             color: 'tan',
                                                             filter: 'url(#shadow)'
                                                         }}
                                                         onClick={handleUpdateDeck}/> :
                                        <FontAwesomeIcon icon={faSave}
                                                         style={{
                                                             fontSize: '2rem',
                                                             color: 'tan',
                                                             filter: 'url(#shadow)'
                                                         }}
                                                         onClick={handleCreateDeck}/>
                                    }
                                </div>
                                {flashMessage && <div className={`flash-message ${flashType}`}>{flashMessage}</div>}
                            </div>
                            <BrowserView>
                                <h2 className="mb-3 mt-5 text-white text-center">Filter</h2>
                            </BrowserView>
                            <div
                                className="top-search-bar bg-transparent text-center d-flex justify-content-center w-100">
                                <Search onSearch={handleSearch} toggleFilter={toggleFilter}/>
                            </div>
                            <div className="second-bar mt-2">
                                <div className="edition-filter text-center">
                                    {deckView === 1 ? ""
                                        :
                                        cardsData.editions.map((edition, index) => (
                                            <>
                                                <button
                                                    key={index}
                                                    className={`edition-button btn ${selectedEdition === edition.name ? 'btn-secondary active' : 'btn-outline-secondary'}`}
                                                    onClick={() => filterByEdition(edition.name)}
                                                >
                                                    {edition.name}
                                                </button>
                                            </>
                                        ))}
                                    <button
                                        className={`edition-button btn ${selectedEdition === 'All' ? 'btn-secondary active' : 'btn-outline-secondary'}`}
                                        onClick={filterAll}
                                    >
                                        All
                                    </button>
                                </div>
                            </div>
                            <div className="filter-section mt-3">
                                <Filter filters={filtersParam} applyFilters={applyFilters} searchTerm={searchTerm}
                                        setSearchTerm={setSearchTerm}/>
                            </div>
                        </div>
                        <div className="col-md-9 col-lg-9">
                            <div className="row card-list-row">
                                {filteredCards.map((card) => (
                                    <div key={card._id} className="col-4 col-md-3 col-lg-2 px-1 mb-2">
                                        <div key={card._id} className="card-container pl-1 pr-1">
                                            <div className="card-container-builder pl-1 pr-1 position-relative">
                                                <img
                                                    key={card.id}
                                                    className="card-img img-fluid mb-0"
                                                    src={`/assets/cards/${card.edition}/EN/${card.cardNumber}_${card.name
                                                        .toLowerCase()
                                                        .replace(/ /g, '_')}_en_cut_100ppi_eng.png`}
                                                    alt={card.name}
                                                    onClick={() => setSelectedCard(card)}
                                                />
                                                {cardQuantities.some(item => item.id === card._id) ? (
                                                    <CardSelection
                                                        cardId={card._id}
                                                        cardName={card.name}
                                                        cardRank={card.rank}
                                                        quantity={cardQuantities.find(item => item.id === card._id).quantity}
                                                        handleDelete={() => handleDelete(card._id)}
                                                        handleIncrement={() => handleIncrement(card._id, card.name)}
                                                        handleDecrement={() => handleDecrement(card._id)}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faPlusCircle}
                                                        style={{
                                                            cursor: 'pointer',
                                                            position: 'absolute',
                                                            bottom: '0',
                                                            left: '50%',
                                                            transform: 'translateX(-50%)',
                                                            fontSize: '2.5rem',
                                                            color: 'lightgrey'
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            handleIncrement(card._id, card.name);
                                                        }}
                                                    />
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {selectedCard && (
                        deckView === 1 ?
                            <CardDetails cardDetails={selectedCard.card} abilities={dAbilities}
                                         onClose={hideCardDetails}/> :
                            <CardDetails cardDetails={selectedCard} abilities={abilities} onClose={hideCardDetails}/>
                    )}
                </div>
            </BrowserView>
            {/* Render the CardDetails component when selectedCard is not null */}
            {selectedCard && (
                deckView === 1 ?
                    <CardDetails cardDetails={selectedCard.card} abilities={dAbilities} onClose={hideCardDetails}/> :
                    <CardDetails cardDetails={selectedCard} abilities={abilities} onClose={hideCardDetails}/>
            )}

        </div>
    );
};

export default Deckbuilderv2;
