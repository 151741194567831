import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../AuthContext';
import './RegistrationForm.css'

const RegistrationForm = ({handleShowLoginForm}) => {
    const { setUser, setUserIsLoggedIn , host, port } = useAuth();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://${host}:${port}/register`, {
                username: username,
                email: email,
                password: password,
            });
            setMessage(response.data.message);
            console.log(response.data.user)
            setUserIsLoggedIn(true);
            const loggedInUser = response.data.user;
            setUser(loggedInUser);
        } catch (error) {
            if (error.response) {
                setError(error.response.data.error);
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

    return (
        <div className="register-component">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-4">
                        <div className="card bg-transparent"  style={{border:"none"}}>
                            <div className="card-body mt-5">
                                <h2 className="card-title text-center mb-4 text-white" style={{fontSize: "30px", textShadow: "2px 2px 4px #000000"}}>Register</h2>
                                {error && <div className="alert alert-danger">{error}</div>}
                                <form onSubmit={handleFormSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label text-white" style={{
                                            fontSize: "18px",
                                            textShadow: "2px 2px 4px #000000"
                                        }}>Username</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            name="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label text-white" style={{
                                            fontSize: "18px",
                                            textShadow: "2px 2px 4px #000000"
                                        }}>E-mail</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label text-white" style={{
                                            fontSize: "18px",
                                            textShadow: "2px 2px 4px #000000"
                                        }}>Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3 text-center">
                                        <button type="submit" className="btn btn-primary w-100">Sign Up</button>
                                    </div>
                                    <div className="mb-3 text-center">
                                        <button className="btn btn-link text-white"
                                                style={{fontSize: "20px", textShadow: "2px 2px 4px #000000"}}
                                                onClick={handleShowLoginForm}>
                                            Already have an account? <br/> Log in
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default RegistrationForm;
