import React, {useEffect, useRef, useState} from 'react';
import CardDetails from "../../Cardetails/CardDetails";
import Search from "../../Search/Search";
import Filter from "../../Search/Filter";
import "./Cards.css";
import { useAuth } from "../../../AuthContext";
import {Dropdown} from "react-bootstrap";

const DeckView = ({ cardsData, gameData, deckView, cAbilities, isCollection, toggleCardSelection, saveCollection, selectedCards, setSelectedCards, userCollection }) => {
    const [selectedCard, setSelectedCard] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCards, setFilteredCards] = useState(cardsData && cardsData.cards ? cardsData.cards : []);
    const [abilities, setAbilities] = useState(cardsData.abilities);
    const [dAbilities, setDAbilities] = useState(cAbilities);
    const { showFilter, setShowFilter, SvgFilters } = useAuth();
    const [appliedFilters, setAppliedFilters] = useState({});
    const filtersParam = { "editions": gameData.editions, "types": gameData.types, "subtypes": gameData.subtypes, "abilities": gameData.abilities, "rarities": gameData.rarities }
    const [selectedEdition, setSelectedEdition] = useState('All');
    const [filters, setFilters] = useState({});const isFilterApplied = Object.keys(filters).length > 0;
    const barRef = useRef(null);
    const [maxButtons, setMaxButtons] = useState(0);
    const scrollRight = () => {
        document.querySelector('.edition-filter').scrollLeft += 100; // Scrolls by 100px; adjust as needed
    };


    // Fonction pour réinitialiser tous les filtres
    const resetFilters = () => {
        setFilters({});
    };





    const filterByEdition = (edition) => {
        setSelectedEdition(edition);
        const filters = { ...appliedFilters, edition: [edition] };
        applyFilters(filters, searchTerm);
    };

    const filterAll = () => {
        setSelectedEdition('All');
        const filters = { ...appliedFilters };
        delete filters.edition; // Supprimer le filtre par édition
        applyFilters(filters, searchTerm);
    };

    const applyFilters = (filters, searchTerm) => {
        setAppliedFilters(filters);
        // Filtrer les cartes en fonction des filtres appliqués
        let filtered = cardsData.cards;

        if (filters.searchTerm) {
            const searchText = filters.searchTerm.toLowerCase();
            filtered = filtered.filter((card) =>
                card.name.toLowerCase().includes(searchText) ||
                card.types.some((type) => type.toLowerCase() === searchText) ||
                card.edition.some((edition) => edition.toLowerCase() === searchText) ||
                card.subtypes.some((subtype) => subtype.toLowerCase() === searchText) ||
                card.summonerSkills.some((skill) => skill.skill.toLowerCase().includes(searchText)) ||
                card.baseEffects.some((effect) => effect.effect.toLowerCase().includes(searchText)) ||
                card.baseEffects.some((effect) => effect.effectDetail.toLowerCase().includes(searchText)) ||
                card.actions.some((action) => action.tossOfCoin.some((toss) => toss.success.description.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.tossOfCoin.some((toss) => toss.success.detail.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.directPurchase.some((purchase) => purchase.action.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.directPurchase.some((purchase) => purchase.actionDetail.toLowerCase().includes(searchText)))
            );
        }

        setFilteredCards(filtered);
    };

    const showCardDetails = (cardId) => {
        const selectedCard = filteredCards.find((card) => card._id === cardId);
        setSelectedCard(selectedCard);
    };

    const hideCardDetails = () => {
        setSelectedCard(null);
    };

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);

        const filtered = cardsData.cards.filter((card) =>
            card.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCards(filtered);
    };

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    function setActive(selectedButton) {
        document.querySelectorAll('.edition-button').forEach(button => {
            button.classList.remove('active');
        });

        selectedButton.classList.add('active');
    }

    const renderEditionButtons = (editions) => {
        const firstThreeEditions = editions.slice(0, 2);

        if (editions.length <= 2) {
            return firstThreeEditions.map((edition, index) => (
                <>
                    <button
                        className={`edition-button btn ${selectedEdition === 'All' ? 'btn-secondary' : 'btn-outline-secondary'}`}
                        onClick={filterAll}
                    >
                        All
                    </button>
                    <button
                        key={index}
                        className={`edition-button btn ${selectedEdition === edition.name ? 'btn-secondary' : 'btn-outline-secondary'}`}
                        onClick={() => filterByEdition(edition.name)}
                    >
                        {edition.name}
                    </button>
                </>
            ));
        } else {
            const otherEditions = editions.slice(2);

            return (
                <div className="d-flex justify-content-around align-items-center">
                    <button
                        className="edition-button btn active mx-1 mx-lg-4"
                        onClick={(e) => {filterAll(); setActive(e.target)}}
                    >
                        All
                    </button>
                    {firstThreeEditions.map((edition, index) => (
                        <button
                            key={index}
                            className="edition-button btn mx-1 mx-lg-4"
                            onClick={(e) => { filterByEdition(edition.name); setActive(e.target) }}
                        >
                            {edition.name}
                        </button>
                    ))}
                    <Dropdown>
                        <Dropdown.Toggle className="edition-button mx-1">
                            Prestige
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {otherEditions.map((edition, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => filterByEdition(edition.name)}
                                >
                                    {edition.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {isCollection && (
                        <button onClick={saveCollection} className="btn edition-button ms-2" >Save</button>
                    )}
                </div>
            );
        }
    };


    const calculateMaxButtons = () => {
        if (barRef.current) {
            const containerWidth = barRef.current.offsetWidth;
            let totalButtonsWidth = 0;
            let maxButtonsCount = 0;

            const buttonElements = barRef.current.querySelectorAll('.edition-button');
            buttonElements.forEach(button => {
                totalButtonsWidth += button.offsetWidth +
                    parseInt(window.getComputedStyle(button).marginLeft, 10) +
                    parseInt(window.getComputedStyle(button).marginRight, 10);
            });

            buttonElements.forEach(button => {
                const buttonWidth = button.offsetWidth +
                    parseInt(window.getComputedStyle(button).marginLeft, 10) +
                    parseInt(window.getComputedStyle(button).marginRight, 10);

                if (totalButtonsWidth <= containerWidth) {
                    maxButtonsCount = buttonElements.length;

                } else if (totalButtonsWidth - buttonWidth < containerWidth) {
                    maxButtonsCount--;
                    totalButtonsWidth -= buttonWidth;
                }
            });

            setMaxButtons(maxButtonsCount);
        }
    };

    // Call calculateMaxButtons on mount and on window resize
    useEffect(() => {
        calculateMaxButtons();
        window.addEventListener('resize', calculateMaxButtons);
        return () => window.removeEventListener('resize', calculateMaxButtons);
    }, []);

    filteredCards.sort((a, b) => parseInt(a.card.cardNumber, 10) - parseInt(b.card.cardNumber, 10));


    return (
        <div className="deckView-component">
            {showFilter ? (
                <Filter filters={filtersParam} applyFilters={applyFilters} searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}/>
            ) : (
                <div className="container card-index">
                    <div className="row card-list-row scrollable mb-3" style={{ paddingTop: '1rem'}}>
                        {/* Render cards based on filteredCards */}
                        {filteredCards
                            .sort((a, b) => parseInt(a.cardNumber, 10) - parseInt(b.cardNumber, 10))
                            .map((card) => (
                                <div key={card._id} className={`col-4 col-md-3 col-lg-2 px-1 mb-2 test`}>
                                    <div key={card._id} className="card-container pl-1 pr-1">
                                        <div style={{position: "relative", width: "fit-content"}}>
                                            <img
                                                key={card.id}
                                                className="card-img img-fluid mb-0 card-container"
                                                loading="eager"
                                                src={`/assets/cards/${card.card.edition[0]}/EN/${card.card.cardNumber}_${card.name
                                                    .toLowerCase()
                                                    .replace(/ /g, '_')}_en_cut_100ppi_eng.png`}
                                                alt={card.name}
                                                onClick={() => isCollection ? toggleCardSelection(card._id) : showCardDetails(card._id)}
                                            />
                                        </div>
                                        {card.card && (
                                            <p className="quantity text-center"
                                               style={{color: (card.quantity - parseInt(card.card.rank) > 0) ? 'red' : 'lightgrey'}}>{card.quantity}</p>
                                        )}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )}
            {/* Render the CardDetails component when selectedCard is not null */}
            {selectedCard && (
                    <CardDetails cardDetails={selectedCard.card} abilities={dAbilities} onClose={hideCardDetails}/>
            )}

        </div>
    );
};

export default DeckView;
