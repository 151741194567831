import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';
import { useSwipeable } from 'react-swipeable';
import './Counter.css';
import Flip from "../Flip/Flip";

const Counter = ({ summoners }) => {


    // Summoner One
    const [currentSummonerOneIndex, setCurrentSummonerOneIndex] = useState(0);
    const currentSummonerOne = summoners[currentSummonerOneIndex] || summoners[0];
    const [quantitySummonerOne, setQuantitySummonerOne] = useState(10);
    const [levelSummonerOne, setLevelSummonerOne] = useState(1);
    const handlePrevSummonerOne = () => {
        setCurrentSummonerOneIndex(prevIndex => (prevIndex === 0 ? summoners.length - 1 : prevIndex - 1));
    };

    const handleNextSummonerOne = () => {
        setCurrentSummonerOneIndex(prevIndex => (prevIndex === summoners.length - 1 ? 0 : prevIndex + 1));
    };


    const swipeHandlersSummonerOne = useSwipeable({
        onSwipedLeft: () => handleNextSummonerOne(),
        onSwipedRight: () => handlePrevSummonerOne(),
        onSwipedDown: (eventData) => {
            eventData.event.preventDefault();
            decreaseLifeSummonerOne();
        },
        onSwipedUp: (eventData) => {
            eventData.event.preventDefault();
            increaseLifeSummonerOne();
        },
    });

    useEffect(() => {
        if (currentSummonerOne) {
            setQuantitySummonerOne(currentSummonerOne.healingPoints);
        }
    }, [currentSummonerOne]);

    const increaseLifeSummonerOne = () => {
        setQuantitySummonerOne(prevQuantity => prevQuantity + 1);
    };

    const decreaseLifeSummonerOne = () => {
        if (quantitySummonerOne > 0) {
            setQuantitySummonerOne(prevQuantity => prevQuantity - 1);
        }
    };

    const resetLifeSummonerOne = () => {
        setQuantitySummonerOne(currentSummonerOne.healingPoints);
    };

    const handleIncreaseLevelSummonerOne = () => {
        setLevelSummonerOne(prevLevel => prevLevel + 1);
    };

    const handleDecreaseLevelSummonerOne = () => {
        if (levelSummonerOne > 1) {
            setLevelSummonerOne(prevLevel => prevLevel - 1);
        }
    };



    // Summoner Two
    const [currentSummonerTwoIndex, setCurrentSummonerTwoIndex] = useState(0);
    const currentSummonerTwo = summoners[currentSummonerTwoIndex] || summoners[0];
    const [quantitySummonerTwo, setQuantitySummonerTwo] = useState(10);
    const [levelSummonerTwo, setLevelSummonerTwo] = useState(1);
    const handlePrevSummonerTwo = () => {
        setCurrentSummonerTwoIndex(prevIndex => (prevIndex === 0 ? summoners.length - 1 : prevIndex - 1));
    };

    const handleNextSummonerTwo = () => {
        setCurrentSummonerTwoIndex(prevIndex => (prevIndex === summoners.length - 1 ? 0 : prevIndex + 1));
    };

    const swipeHandlersSummonerTwo = useSwipeable({
        onSwipedLeft: () => handleNextSummonerTwo(),
        onSwipedRight: () => handlePrevSummonerTwo(),
        onSwipedDown: (eventData) => {
            eventData.event.preventDefault();
            decreaseLifeSummonerTwo();
        },
        onSwipedUp: (eventData) => {
            eventData.event.preventDefault();
            increaseLifeSummonerTwo();
        },
    });

    useEffect(() => {
        if (currentSummonerTwo) {
            setQuantitySummonerTwo(currentSummonerTwo.healingPoints);
        }
    }, [currentSummonerTwo]);
    const increaseLifeSummonerTwo = () => {
        setQuantitySummonerTwo(prevQuantity => prevQuantity + 1);
    };

    const decreaseLifeSummonerTwo = () => {
        if (quantitySummonerTwo > 0) {
            setQuantitySummonerTwo(prevQuantity => prevQuantity - 1);
        }
    };

    const resetLifeSummonerTwo = () => {
        setQuantitySummonerTwo(currentSummonerTwo.healingPoints);
    };

    const handleIncreaseLevelSummonerTwo = () => {
        setLevelSummonerTwo(prevLevel => prevLevel + 1);
    };

    const handleDecreaseLevelSummonerTwo = () => {
        if (levelSummonerTwo > 1) {
            setLevelSummonerTwo(prevLevel => prevLevel - 1);
        }
    };

    // Stock
    const [stockQuantity, setStockQuantity] = useState(1);
    const decreaseStockQuantity = () => {
        if (stockQuantity > 0) {
            setStockQuantity(prevQuantity => prevQuantity - 1);
        }
    };

    const increaseStockQuantity = () => {
        setStockQuantity(prevQuantity => prevQuantity + 1);
    };

    const resetStock = () => {
        setStockQuantity(1);
    };

    const swipeHandlersStock = useSwipeable({
        onSwipedLeft: () => decreaseStockQuantity(),
        onSwipedRight: () => increaseStockQuantity(),
    });

    // Ether
    const [etherQuantity, setEtherQuantity] = useState(0);
    const decreaseEtherQuantity = () => {
        if (etherQuantity > 0) {
            setEtherQuantity(prevQuantity => prevQuantity - 1);
        }
    };

    const increaseEtherQuantity = () => {
        setEtherQuantity(prevQuantity => prevQuantity + 1);
    };

    const resetEther = () => {
        setEtherQuantity(0);
    };

    const swipeHandlersEther = useSwipeable({
        onSwipedLeft: () => decreaseEtherQuantity(),
        onSwipedRight: () => increaseEtherQuantity(),
    });

    useEffect(() => {
        let startY = 0; // Stocke la position Y au début du toucher

        const isScrollableElement = (target) => {
            while (target !== document.body && target !== null) { // Remonte jusqu'à la racine ou jusqu'à trouver un élément scrollable
                const overflowY = window.getComputedStyle(target).overflowY;
                const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
                if (isScrollable && target.scrollHeight > target.clientHeight) {
                    return true; // L'élément ou un parent est scrollable
                }
                if (target.classList.contains('exception')) {
                    return false; // Retourne false si l'élément ou un parent possède la classe 'exception'
                }
                target = target.parentNode; // Continue avec le parent
            }
            return false; // Aucun élément scrollable trouvé
        };

        const handleTouchStart = (e) => {
            startY = e.touches[0].clientY; // Enregistre la position Y au début du toucher
        };

        const handleTouchMove = (e) => {
            if (isScrollableElement(e.target)) {
                return; // Ne fait rien si l'élément touché est scrollable
            }

            const moveY = e.touches[0].clientY; // Obtient la position Y actuelle du toucher
            const diffY = moveY - startY; // Calcule la différence de position Y

            // Si diffY est positif, cela signifie que l'utilisateur swip vers le bas
            if (diffY > 0) {
                e.preventDefault(); // Empêche le comportement par défaut (par exemple, le scroll ou le refresh)
            }
        };

        // Ajoute les écouteurs d'événements au document
        document.addEventListener('touchstart', handleTouchStart, { passive: false });
        document.addEventListener('touchmove', handleTouchMove, { passive: false });

        // Supprime les écouteurs d'événements lors du nettoyage de l'effet
        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);

    const SvgFilters = () => (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ height: 0, width: 0, position: 'absolute' }}>
            <defs>
                <filter id="shadow" height="130%">
                    <feDropShadow dx="2" dy="2" stdDeviation="2" floodColor="black" />
                </filter>
            </defs>
        </svg>
    );

    return (
        <div className="counter-component">
            <SvgFilters />
            <div className="container-fluid">
                <div className="row d-flex">
                    {/* First Summoner */}
                    <div className="col-6 summoner-one">
                        <div className="card bg-transparent border-0">
                            <div>
                                <div className="carousel slide" data-ride="carousel" {...swipeHandlersSummonerOne}>
                                    <div className="carousel-inner">
                                        {summoners.map((summoner, index) => (
                                            <div key={index}
                                                 className={`carousel-item ${index === currentSummonerOneIndex ? 'active' : ''}`}>
                                                <img
                                                    className={`d-block w-100 ${quantitySummonerOne === 0 ? 'grayscale' : ''}`}
                                                    src={`assets/cards/${summoner.edition}/EN/hd/${summoner.cardNumber}_${summoner.name.toLowerCase().replace(/ /g, '_')}_en.png`}
                                                    alt="Card Image"/>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="summoner-controls">
                                        <button className="carousel-control-prev prev-summoner-one"
                                                onClick={handlePrevSummonerOne}>
                                            <FontAwesomeIcon icon={faChevronLeft} className="chevron" style={{ filter: 'url(#shadow)' }}/>
                                        </button>
                                        <button className="carousel-control-next next-summoner-one"
                                                onClick={handleNextSummonerOne}>
                                            <FontAwesomeIcon icon={faChevronRight} className="chevron" style={{ filter: 'url(#shadow)' }}/>
                                        </button>
                                        <div
                                            className="summoner-life text-center d-flex justify-content-center align-items-center mt-2 me-1">
                                            <input onFocus={resetLifeSummonerOne} type="number" className="quantity-input-summoner-one text-white mx-2"
                                                   value={quantitySummonerOne} readOnly/>
                                        </div>
                                        <button className="carousel-control-prev-level prev-life-summoner-one"
                                                onClick={decreaseLifeSummonerOne}>
                                            <FontAwesomeIcon icon={faMinus} className="chevron" style={{ filter: 'url(#shadow)' }} size="2x"/>
                                        </button>
                                        <button className="carousel-control-next-level next-life-summoner-one"
                                                onClick={increaseLifeSummonerOne}>
                                            <FontAwesomeIcon icon={faPlus} className="chevron" style={{ filter: 'url(#shadow)' }} size="2x"/>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* Second Summoner */}
                    <div className="col-6 summoner-two">
                        <div className="card bg-transparent border-0">
                            <div>
                                <div className="carousel slide" data-ride="carousel"
                                     {...swipeHandlersSummonerTwo}>
                                    <div className="carousel-inner">
                                        {summoners.map((summoner, index) => (
                                            <div key={index}
                                                 className={`carousel-item ${index === currentSummonerTwoIndex ? 'active' : ''}`}>
                                                <img
                                                    className={`d-block w-100 ${quantitySummonerTwo === 0 ? 'grayscale' : ''}`}
                                                    src={`assets/cards/${summoner.edition}/EN/hd/${summoner.cardNumber}_${summoner.name.toLowerCase().replace(/ /g, '_')}_en.png`}
                                                    alt="Card Image"/>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="summoner-controls">
                                        <button className="carousel-control-prev prev-summoner-two"
                                                onClick={handlePrevSummonerTwo}>
                                            <FontAwesomeIcon icon={faChevronLeft} className="chevron" style={{ filter: 'url(#shadow)' }}/>
                                        </button>
                                        <button className="carousel-control-next next-summoner-two"
                                                onClick={handleNextSummonerTwo}>
                                            <FontAwesomeIcon icon={faChevronRight} className="chevron" style={{ filter: 'url(#shadow)' }}/>
                                        </button>
                                        <div
                                            className="summoner-life text-center d-flex justify-content-center align-items-center mt-2 me-1">
                                            <input onClick={resetLifeSummonerTwo}  type="number" className="quantity-input-summoner-two text-white mx-2"
                                                   value={quantitySummonerTwo} readOnly/>
                                        </div>
                                        <button className="carousel-control-prev-level prev-life-summoner-two"
                                                onClick={decreaseLifeSummonerTwo}>
                                            <FontAwesomeIcon icon={faMinus} className="chevron" style={{ filter: 'url(#shadow)' }} size="2x"/>
                                        </button>
                                        <button className="carousel-control-next-level next-life-summoner-two"
                                                onClick={increaseLifeSummonerTwo}>
                                            <FontAwesomeIcon icon={faPlus} className="chevron" style={{ filter: 'url(#shadow)' }} size="2x"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container flip-stock-ether ">
                {/* Flip Card */}
                <Flip/>
                {/* Stock */}
                <div className="stock row mt-2 align-items-center" {...swipeHandlersStock}>
                    <div className="col-4 text-center">
                        <SvgFilters/>
                        <FontAwesomeIcon onClick={decreaseStockQuantity} icon={faMinus} className="text-white" size="3x" style={{ filter: 'url(#shadow)' }}/>
                    </div>
                    <div className="col-4 text-center">
                        <input onClick={resetStock} type="number"
                               className="form-control form-control-plaintext d-inline-block w-50 text-center bg-transparent text-white"
                               value={stockQuantity}
                               readOnly
                        />
                    </div>
                    <div className="col-4 text-center">
                        <FontAwesomeIcon onClick={increaseStockQuantity} icon={faPlus} className="text-white" size="3x" style={{ filter: 'url(#shadow)' }}/>
                    </div>
                </div>
                {/* Ether */}
                <div className="ether row mt-2 align-items-center" {...swipeHandlersEther}>
                    <div className="col-4 text-center">
                        <FontAwesomeIcon onClick={decreaseEtherQuantity} icon={faMinus} className="text-white" size="3x" style={{ filter: 'url(#shadow)' }}/>
                    </div>
                    <div className="col-4 text-center">
                        <input onClick={resetEther} type="number"
                               className="form-control form-control-plaintext d-inline-block w-50 text-center bg-transparent text-white"
                               value={etherQuantity}
                               readOnly
                        />
                    </div>
                    <div className="col-4 text-center">
                        <FontAwesomeIcon onClick={increaseEtherQuantity} icon={faPlus} className="text-white" size="3x" style={{ filter: 'url(#shadow)' }}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Counter;