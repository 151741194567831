import React, { useState } from "react";
import Cards from "./Cards/Cards";
import Abilities from "./Abilities/Abilities";
import {BrowserView, MobileView} from "react-device-detect";

const Index = ({ cardsData }) => {
    const [showCards, setShowCards] = useState(true);

    const handleToggleView = () => {
        setShowCards(!showCards);
    };

    return (
        <div className="index-component exception" style={{ position: "relative", height: "100vh", overflow: "hidden" }}>
            <MobileView>
                <button
                    onClick={handleToggleView}
                    style={{
                        position: "absolute",
                        left: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        color: "white",
                        textShadow: "2px 2px 4px #000000",
                        zIndex: 10 // Ensure the button is above other content
                    }}
                >
                    <span style={{fontSize: "2em"}}>◀</span>
                </button>
            </MobileView>
            <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                {showCards ? (
                    <Cards cardsData={cardsData} abilities={cardsData.abilities} collectionView={false}/>
                ) : (
                    <Abilities abilities={cardsData.abilities}/>
                )}
            </div>
                <button
                    onClick={handleToggleView}
                    style={{
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        color: "white",
                        textShadow: "2px 2px 4px #000000",
                        zIndex: 10 // Ensure the button is above other content
                    }}
                >
                    <span style={{fontSize: "2em"}}>▶</span>
                </button>
        </div>
    );
};

export default Index;
