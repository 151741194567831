// VideoLauncher.js
import React, { useRef, useEffect } from 'react';
import './VideoLauncher.css'

const VideoLauncher = ({ onVideoEnd }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('ended', () => {
                onVideoEnd();
            });
        }
    }, [videoRef, onVideoEnd]);

    return (
        <div className="video-container">
            <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                width="100%"
                className="fullscreen-video"
            >
                <source src="/assets/intro4.mp4" type="video/mp4" />
            </video>
        </div>
    );
};

export default VideoLauncher;
