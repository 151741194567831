import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import "./Flip.css"

const Flip = () => {
    const [showModal, setShowModal] = useState(false);
    const [flipResult, setFlipResult] = useState('');
    const [randomNumber, setRandomNumber] = useState(null);

    const handleShowFlip = () => {
        setShowModal(true);
        generateRandomNumber();
    }

    const generateRandomNumber = () => {
        const randomNumber = Math.floor(Math.random() * 100) + 1;
        setRandomNumber(randomNumber);
        if (randomNumber % 2 === 0) {
            setFlipResult('side-a'); // Pair : Affiche Success.mp4
        } else {
            setFlipResult('side-b'); // Impair : Affiche Fail.mp4
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFlipResult('');
    };

    const handleVideoEnd = () => {
        handleCloseModal();
    };

    return (
        <div className="flip" onClick={handleShowFlip}>
            <div className="col-12">
                <div className="d-flex flex-row align-items-center justify-content-center flip-inner mt-4">
                    <h2 className="mb-0 mr-3 mt-4">Flip</h2>
                    <img id="flip-coin" src="assets/icons/face_coin.png" alt="Coin" className="img-fluid mb-0 mt-4"/>
                    <h2 className="mb-0 ml-3 mt-4">Coin</h2>
                    <Modal show={showModal} onHide={handleCloseModal} centered>
                        <Modal.Body>
                            <div id="toss-result" className="text-center">
                                {flipResult === 'side-a' ?
                                    <video
                                        autoPlay
                                        muted
                                        onEnded={handleVideoEnd}
                                        className="w-100"
                                    >
                                        <source src="/assets/Success.mp4" type="video/mp4"/>
                                    </video> :
                                    flipResult === 'side-b' &&
                                    <video
                                        autoPlay
                                        muted
                                        onEnded={handleVideoEnd}
                                        className="w-100"
                                    >
                                        <source src="/assets/Fail.mp4" type="video/mp4"/>
                                    </video>}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    )
};

export default Flip;
