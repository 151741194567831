import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";

const CardSelection = ({ quantity, handleDelete, handleIncrement, handleDecrement, cardName, cardRank }) => {
    const isExceedingRank = quantity -1 >= cardRank;
    return (
        <div className="card-selection-componant text-center">
            <div className="d-flex justify-content-around align-items-center">
                {quantity === 1 ? (
                    <div className="mr-2" onClick={handleDelete}>
                        <FontAwesomeIcon icon={faTrash} style={{ cursor: 'pointer', fontSize: '1.5rem', color: 'lightgrey' }} />
                    </div>
                ) : (
                    <div className="mr-2" onClick={handleDecrement}>
                        <FontAwesomeIcon icon={faMinusCircle} style={{ cursor: 'pointer', fontSize: '1.5rem', color: 'lightgrey'}} />
                    </div>
                )}

                <div style={{ cursor: 'pointer', fontSize: '1.5rem', color: isExceedingRank ? 'red' : 'lightgrey' }}>
                    {quantity}
                </div>

                <div className="ml-2" onClick={handleIncrement}>
                    <FontAwesomeIcon icon={faPlusCircle} style={{ cursor: 'pointer', fontSize: '1.5rem', color: 'lightgrey'}} />
                </div>
            </div>
        </div>
    );
};

export default CardSelection;
