import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from "./components/Main/Main";
import { AuthProvider } from './AuthContext';
import { useEffect, useState } from "react";
import "./App.css"

const App = () => {
    const userIsLoggedIn = false;


    return (
        <AuthProvider>
                <Router>
                    <Routes>
                        {/* Route pour le composant Home */}
                        <Route path="/" element={<Main userIsLoggedIn={userIsLoggedIn}/>}/>

                        {/* Route pour le composant d'inscription */}
                        <Route path="/register" element={<Main signIn={true}/>}/>
                    </Routes>
                </Router>
        </AuthProvider>
    );
};

export default App;
