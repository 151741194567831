import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import axios from 'axios';
import DeckView from "../Index/Cards/DeckView";
import { useAuth } from '../../AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisV,
    faFileExport,
    faFileAlt,
    faCopy,
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import "./PublicDeckList.css";

const PublicDeckList = forwardRef(({ cardsData, gameData }, ref) => {
    useImperativeHandle(ref, () => ({
        resetDeckList: () => {
            setDecks(null); // Réinitialiser l'état lié au deck chargé
        },
    }));

    const { user, userIsLoggedIn, host, port } = useAuth();
    const [abilities, setAbilities] = useState(cardsData.abilities);
    const [decks, setDecks] = useState([]); // Initialisation correcte de l'état decks
    const [selectedDeckData, setSelectedDeckData] = useState(null);
    const [isModalDeckViewVisible, setIsModalDeckViewVisible] = useState(false);
    const [errorDeckIndex, setErrorDeckIndex] = useState(null);
    const [isKebabMenuOpen, setIsKebabMenuOpen] = useState({});

    const toggleKebabMenu = (index) => {
        setIsKebabMenuOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const fetchDecks = () => {
        fetch(`https://${host}:${port}/decks`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setDecks(data || []); // S'assurer que data.decks est un tableau
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        fetchDecks();
    }, [host, port]); // Ajouter des dépendances pour éviter les erreurs

    const loadDeck = async (deckId) => {
        try {
            const response = await axios.get(`https://${host}:${port}/deck/view/${deckId}`);
            setSelectedDeckData(response.data);
            setIsModalDeckViewVisible(true);
        } catch (error) {
            console.error('Error fetching deck details:', error);
        }
    };

    const exportDeck = async (deckId, format) => {
        const url = `https://${host}:${port}/export/${format}/deck/${deckId}`;
        try {
            const response = await fetch(url, { method: 'GET' });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `deck_${deckId}.${format}`;
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Failed to export deck:', error);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                showFlashMessage("TTS export Number Copied");
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const showFlashMessage = (message) => {
        const deckImageDiv = document.querySelector('.deck-image');
        const errorDeckDiv = deckImageDiv ? deckImageDiv.querySelector('.error-deck') : null;

        if (errorDeckDiv) {
            const messageElement = document.createElement("div");
            messageElement.textContent = message;
            messageElement.style.backgroundColor = "lightgreen";
            messageElement.style.padding = "10px";
            messageElement.style.borderRadius = "5px";
            messageElement.style.color = "white";
            messageElement.style.textAlign = "center";
            messageElement.style.marginTop = "10px";

            errorDeckDiv.appendChild(messageElement);

            setTimeout(() => {
                errorDeckDiv.removeChild(messageElement);
            }, 3000);
        } else {
            console.error("Element to display flash message not found.");
        }
    };

    const duplicateDeck = async (deckId) => {
        try {
            const response = await axios.post(`https://${host}:${port}/deck/duplicate`, {
                userId: user._id,
                deckId: deckId,
            });
            console.log('Deck duplicated successfully:', response.data);
            fetchDecks(); // Rafraîchit la liste des decks après la duplication
        } catch (error) {
            console.error('Error duplicating deck:', error);
        }
    };

    return (
        <div className="public-decklist-component">
            <div className="">
                {isModalDeckViewVisible ? (
                    <div className="deck-view d-flex flex-column align-items-center justify-content-center">
                        <div className="content">
                            <h1 className="text-white text-center fw-bold">{selectedDeckData.deck.name}</h1>
                            <hr className="w-50 mx-auto text-white" />
                            <DeckView cardsData={selectedDeckData.deck} gameData={gameData} cAbilities={abilities}
                                      setisModalDeckViewVisible={true} setIsDeckBuilderVisible={false} />
                        </div>
                    </div>
                ) : (
                    <div className="public-decks-section mb-5">
                        {!isModalDeckViewVisible && (
                            <div>
                                <h1 className="text-white text-center fw-bold">Community Decks</h1>
                                <hr className="w-50 mx-auto text-white" />
                                <div className="container">
                                    <div className="row">
                                        {decks.map((deck, index) => (
                                            <div key={deck._id} className="col-12 col-md-4 mb-4">
                                                <div
                                                    className={`card deck-container text-center ${index % 2 === 0 ? 'me-lg-1' : ''}`}>
                                                    <div
                                                        className="card-header deck-title d-flex justify-content-between align-items-center">
                                                        <h3 className="card-title deck-name">{deck.name}</h3>
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            position: 'relative'
                                                        }}>
                                                            <div className="deck-toolbar">
                                                                <FontAwesomeIcon icon={faEllipsisV} style={{
                                                                    fontSize: '2rem',
                                                                    color: "white",
                                                                    cursor: 'pointer'
                                                                }} onClick={() => toggleKebabMenu(index)} />
                                                                {isKebabMenuOpen[index] && (
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: 50,
                                                                        right: 0,
                                                                        backgroundColor: '#6b6450',
                                                                        color: 'lightgray',
                                                                        borderRadius: '5px',
                                                                        padding: '10px',
                                                                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        gap: '10px'
                                                                    }}>
                                                                        <div onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            exportDeck(deck._id, 'json');
                                                                        }} title="Export as JSON">
                                                                            <FontAwesomeIcon icon={faFileExport}
                                                                                             style={{
                                                                                                 marginRight: '5px',
                                                                                                 fontSize: '2rem',
                                                                                                 cursor: "pointer"
                                                                                             }} />
                                                                        </div>
                                                                        <div onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            exportDeck(deck._id, 'txt');
                                                                        }} title="Export as TXT">
                                                                            <FontAwesomeIcon icon={faFileAlt} style={{
                                                                                marginRight: '5px',
                                                                                fontSize: '2rem',
                                                                                cursor: "pointer"
                                                                            }} />
                                                                        </div>
                                                                        <div onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            copyToClipboard(deck._id);
                                                                        }} title="Copy TTS Export Number">
                                                                            <FontAwesomeIcon icon={faCopy} style={{
                                                                                marginRight: '5px',
                                                                                fontSize: '2rem',
                                                                                cursor: "pointer"
                                                                            }} />
                                                                        </div>
                                                                        {userIsLoggedIn && (
                                                                            <div onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                duplicateDeck(deck._id);
                                                                            }} title="Copy Deck">
                                                                                <FontAwesomeIcon icon={faPlus}
                                                                                                 style={{
                                                                                                     marginRight: '5px',
                                                                                                     fontSize: '2rem',
                                                                                                     cursor: "pointer"
                                                                                                 }} />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body deck-image" onClick={(e) => {
                                                        e.preventDefault();
                                                        loadDeck(deck._id);
                                                    }}>
                                                        <div className="error-deck">
                                                            {deck.haveError && errorDeckIndex === index && (
                                                                <div className="message alert alert-danger">
                                                                    {deck.listOfError.map((error, i) => (
                                                                        <span key={i}>| {error} |</span>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
});

export default PublicDeckList;
