import React, { useEffect, useState } from 'react';
import { useAuth } from "../../AuthContext";
import "./Filter.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown, faCircleChevronUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { MobileView } from "react-device-detect";

const Filter = ({ filters, applyFilters }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFilters, setSelectedFilters] = useState({});
    const { showFilter, setShowFilter } = useAuth();
    const [typesVisible, setTypesVisible] = useState(false);
    const [subtypesVisible, setSubtypesVisible] = useState(false);
    const [abilitiesVisible, setAbilitiesVisible] = useState(false);
    const [raritiesVisible, setRaritiesVisible] = useState(false);
    const [editionsVisible, setEditionsVisible] = useState(false);

    const toggleTypes = () => {
        setTypesVisible(!typesVisible);
    };

    const toggleSubtypes = () => {
        setSubtypesVisible(!subtypesVisible);
    };

    const toggleAbilities = () => {
        setAbilitiesVisible(!abilitiesVisible);
    };

    const toggleRarities = () => {
        setRaritiesVisible(!raritiesVisible);
    };

    const toggleEditions = () => {
        setEditionsVisible(!editionsVisible);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFilterChange = (filterName, value) => {
        setSelectedFilters((prevFilters) => {
            const currentFilters = prevFilters[filterName] || [];
            let newFilters;

            if (currentFilters.includes(value)) {
                // Remove the value from the filter array
                newFilters = currentFilters.filter((item) => item !== value);
            } else {
                // Add the value to the filter array
                newFilters = [...currentFilters, value];
            }

            const updatedFilters = {
                ...prevFilters,
                [filterName]: newFilters,
            };

            // If no filters are selected for a category, remove the category from selectedFilters
            if (newFilters.length === 0) {
                delete updatedFilters[filterName];
            }

            // Apply filters based on updatedFilters
            applyFilters(updatedFilters);

            return updatedFilters;
        });
    };

    const handleResetFilters = () => {
        setSelectedFilters({});
        applyFilters({});
    };

    const closeFilters = () => {
        setShowFilter(!showFilter);
    };

    return (
        <div className="filter-container text-center">
            <MobileView>
                <h2 className="mb-3 mt-5 text-white">Filtres</h2>
            </MobileView>
            <input
                type="text"
                placeholder="Rechercher un terme"
                value={searchTerm}
                onChange={handleSearchChange}
                className="form-control mb-2"
            />
            <div className="row g-2">
                {/* Section des Types */}
                <div className="col-12">
                    <div className="card-body rounded" style={{ border: "none" }}>
                        <button onClick={toggleTypes} className="btn btn-filter collapse-button justify-content-center">
                            Types
                            {typesVisible ?
                                <FontAwesomeIcon icon={faCircleChevronUp} style={{ color: "white", margin: "0 1em" }} /> :
                                <FontAwesomeIcon icon={faCircleChevronDown} style={{ color: "grey", margin: "0 1em" }} />
                            }
                        </button>
                        {typesVisible && (
                            <div className="card-body row mb-1 pb-2 pt-4">
                                {filters.types
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((type) => (
                                        <div className="col-6 col-md-6 pe-1 mb-1" key={type._id}>
                                            <label className="checkbox-label d-flex align-items-center text-start">
                                                <input
                                                    className="ms-2"
                                                    type="checkbox"
                                                    checked={selectedFilters['type']?.includes(type.name) || false}
                                                    onChange={() => handleFilterChange('type', type.name)}
                                                />
                                                <img className="icon-filter ms-1"
                                                     src={`/assets/icons/types/${type.name}.png`}
                                                     alt={type.name} />
                                                {type.name}
                                            </label>
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                </div>
                {/* Section des Sous-Types */}
                <div className="col-12">
                    <div className="card-body rounded" style={{ border: "none" }}>
                        <button onClick={toggleSubtypes}
                                className="btn btn-filter collapse-button justify-content-center">
                            Sous-types
                            {subtypesVisible ?
                                <FontAwesomeIcon icon={faCircleChevronUp} style={{ color: "white", margin: "0 1em" }} /> :
                                <FontAwesomeIcon icon={faCircleChevronDown} style={{ color: "grey", margin: "0 1em" }} />
                            }
                        </button>
                        {subtypesVisible && (
                            <div className="card-body row mb-1 pb-2 pt-4">
                                {filters.subtypes
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((subtype) => (
                                        <div className="col-6 col-md-6 pe-1 mb-1" key={subtype._id}>
                                            <label className="checkbox-label d-flex align-items-center text-start">
                                                <input
                                                    className="ms-5 me-3"
                                                    type="checkbox"
                                                    checked={selectedFilters['subtype']?.includes(subtype.name) || false}
                                                    onChange={() => handleFilterChange('subtype', subtype.name)}
                                                />
                                                {subtype.name}
                                            </label>
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                </div>
                {/* Section des Capacités */}
                <div className="col-12">
                    <div className="card-body rounded" style={{ border: "none" }}>
                        <button onClick={toggleAbilities}
                                className="btn btn-filter collapse-button justify-content-center">
                            Capacités
                            {abilitiesVisible ?
                                <FontAwesomeIcon icon={faCircleChevronUp} style={{ color: "white", margin: "0 1em" }} /> :
                                <FontAwesomeIcon icon={faCircleChevronDown} style={{ color: "grey", margin: "0 1em" }} />
                            }
                        </button>
                        {abilitiesVisible && (
                            <div className="card-body row mb-1 me-5 pt-2">
                                {filters.abilities
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((ability) => (
                                        <div className="col-6 col-md-6 mb-1" key={ability._id}>
                                            <label className="checkbox-label d-flex align-items-center">
                                                <input
                                                    className="ms-5"
                                                    type="checkbox"
                                                    checked={selectedFilters['ability']?.includes(ability.name) || false}
                                                    onChange={() => handleFilterChange('ability', ability.name)}
                                                />
                                                <img className="icon-filter ms-2"
                                                     src={`/assets/icons/abilities/${ability.name}.png`}
                                                     alt={ability.name} />
                                                {ability.name}
                                            </label>
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                </div>
                {/* Section des Raretés */}
                <div className="col-12">
                    <div className="card-body rounded" style={{ border: "none" }}>
                        <button onClick={toggleRarities}
                                className="btn btn-filter collapse-button justify-content-center">
                            Raretés
                            {raritiesVisible ?
                                <FontAwesomeIcon icon={faCircleChevronUp} style={{ color: "white", margin: "0 1em" }} /> :
                                <FontAwesomeIcon icon={faCircleChevronDown} style={{ color: "grey", margin: "0 1em" }} />
                            }
                        </button>
                        {raritiesVisible && (
                            <div className="card-body row mb-1 pb-2 pt-4">
                                {filters.rarities
                                    .map((rarity) => (
                                        <div className="col-6 col-md-6 pe-1 mb-1" key={rarity}>
                                            <label className="checkbox-label d-flex align-items-center">
                                                <input
                                                    className="ms-5 me-2"
                                                    type="checkbox"
                                                    checked={selectedFilters['rarity']?.includes(rarity) || false}
                                                    onChange={() => handleFilterChange('rarity', rarity)}
                                                />
                                                {rarity}
                                            </label>
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                </div>

            </div>
            <div className="mt-5 d-flex flex-column">
                <button className="btn apply-filter-button mt-1 mb-3" onClick={handleResetFilters}>Réinitialiser les filtres</button>
            </div>
            <MobileView>
                <button className="btn-close-red" onClick={closeFilters} aria-label="Fermer">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </MobileView>
        </div>
    );
};

export default Filter;
