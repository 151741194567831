import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';
import "./Main.css";
import Home from "../Home/Home";
import Counter from "../Counter/Counter";
import Dashboard from "../User/Dashboard";
import LoginForm from "../User/LoginForm";
import RegistrationForm from '../User/RegistrationForm';
import VideoLauncher from "../VideoLauncher";
import Cookies from "js-cookie";
import axios from "axios";
import { BrowserView, MobileView } from 'react-device-detect';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBoxesStacked,
    faCoins,
    faHome,
    faList, faQuestion,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import Index from "../Index/Index";
import PublicDeckList from "../User/PublicDeckList";




const Main = () => {
    const { userIsLoggedIn, setUserIsLoggedIn, setShowFilter , host, port, user, setUser } = useAuth();
    const [showIndex, setShowIndex] = useState(false);
    const [showDecks, setShowDecks] = useState(false);
    const [showCounter, setShowCounter] = useState(false);
    const [showDashboard, setShowDashboard] = useState(false);
    const [showDeckBuilder, setShowDeckBuilder] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [cardsData, setCardsData] = useState({
        cards: [],
        abilities: [],
    });
    const [decksData, setDecksData] = useState([]);
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);
    const [isVideoComplete, setIsVideoComplete] = useState(false);
    const [key, setKey] = useState(0); // Clé pour forcer le rechargement
    const [deckListKey, setDeckListKey] = useState(0);
    const [dashboardKey, setDashboardKey] = useState(0);
    const [deckKey, setDeckKey] = useState(0); // Clé pour forcer le rechargement
    const isBrowserView = typeof window !== "undefined" && !window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/);

    const verifyToken = async (token) => {
        try {
            const response = await axios.post(`https://${host}:${port}/verify-token`, { token });

            if (response.status === 200) {
                console.log('Token verified: User is logged in.');
                setUserIsLoggedIn(true);
                const loggedInUser = response.data.decoded.user; // Utilisez les données décodées du token pour l'utilisateur
                console.log(response.data.decoded)
                console.log(Cookies.get('logToken'))
                setUser(loggedInUser);
            } else {
                console.log('Token verification failed: User is not logged in.');
                setUserIsLoggedIn(false); // Utilisateur non connecté
                // Effacer le token du localStorage si la vérification échoue
                Cookies.remove('logToken');
            }
        } catch (error) {
            console.error('Error verifying token:', error);
        }
    };



    const handleVideoEnd = () => {
        setIsVideoComplete(true);
    };

    const handleShowRegistrationForm = () => {
        setShowRegistrationForm(true);
    };

    const handleShowLoginForm = () => {
        setShowLogin(true);
        setShowRegistrationForm(false);
    };


    useEffect(() => {
        /*const checkDeviceType = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth <= 768) {
                setIsVideoComplete(false); // Pour les appareils mobiles et tablettes
            } else {
                setIsVideoComplete(true); // Pour les navigateurs de bureau
            }
        };

        // Écoutez les changements de taille de la fenêtre pour détecter le changement de type d'appareil
        window.addEventListener('resize', checkDeviceType);

        // Vérifiez le type d'appareil au chargement initial de la page
        checkDeviceType();

        // Nettoyez l'écouteur d'événement lorsque le composant est démonté
        return () => {
            window.removeEventListener('resize', checkDeviceType);
        };*/

        const logToken = Cookies.get('logToken');
        if (logToken) {
            verifyToken(logToken)
        }

        fetch(`https://${host}:${port}/cards`)
            .then((response) => response.json())
            .then((data) => {
                setCardsData({
                    cards: data.cards,
                    abilities: data.abilities,
                    editions: data.editions,
                    types: data.types,
                    subtypes: data.subtypes,
                    rarities: data.rarities
                });
            })
            .catch((error) => console.error(error));

        fetch(`https://${host}:${port}/decks`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setDecksData(data);
            })
            .catch((error) => console.error(error));
    }, []);

    const summoners = cardsData.cards.filter(card => card.types.includes('Summoner'));

    const gameData = cardsData

    const handleDecksClick = () => {
        setShowDecks(true);
        setShowIndex(false);
        setShowCounter(false);
        setShowDashboard(false);
        setShowLogin(false);
        setShowDeckBuilder(false);
        setShowFilter(false);
        setDeckListKey(prevKey => prevKey + 1);
    };

    const handleIndexClick = () => {
        setShowIndex(true);
        setShowDecks(false);
        setShowCounter(false);
        setShowDashboard(false);
        setShowLogin(false);
        setShowDeckBuilder(false);
        setShowFilter(false);
        setKey(prevKey => prevKey + 1);
    };

    const handleCounterClick = () => {
        setShowIndex(false);
        setShowDecks(false);
        setShowCounter(true);
        setShowDashboard(false);
        setShowLogin(false);
        setShowDeckBuilder(false);
        setShowFilter(false);
    };

    const handleDashboardClick = () => {
        setShowIndex(false);
        setShowDecks(false);
        setShowCounter(false);
        setShowDashboard(true);
        setShowLogin(false);
        setShowDeckBuilder(false);
        setShowFilter(false);
        setDashboardKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        // Update the states based on userIsLoggedIn
        if (userIsLoggedIn) {
            setShowRegistrationForm(false);
            setShowLogin(false);
            setShowDashboard(true)
        }
    }, [userIsLoggedIn]);

    const handleLoginClick = () => {
        setShowIndex(false);
        setShowDecks(false);
        setShowCounter(false);
        setShowDashboard(false);
        setShowLogin(true);
        setShowRegistrationForm(false);
        setShowDeckBuilder(false);
        setShowFilter(false);
    };

    useEffect(() => {
        console.log(decksData)
    }, []);

    useEffect(() => {
        setShowIndex(true);
    }, []);


    return (
        <div className='app-container'>
            {showDecks && <PublicDeckList key={deckListKey} cardsData={cardsData} gameData={gameData} publicDecks={decksData}/>}
            {showCounter && <Counter summoners={summoners}/>}
            {userIsLoggedIn && (showDashboard ? <Dashboard key={dashboardKey} userIsLoggedIn={userIsLoggedIn} cardsData={cardsData}
                                                           abilities={cardsData.abilities} gameData={gameData} publicDecks={decksData}/> : null)}
            {showLogin && (
                <div>
                    {showRegistrationForm ? (
                        <>
                            <RegistrationForm handleShowLoginForm={handleShowLoginForm}/>
                        </>
                    ) : (
                        <>
                            <LoginForm handleShowRegistrationForm={handleShowRegistrationForm}/>
                        </>
                    )}
                </div>
            )}
            {isVideoComplete ? (
                <div>
                    {showIndex && <Index key={key} cardsData={cardsData} abilities={cardsData.abilities} collectionView={false}/>}
                    <nav className="navbar navbar-expand-lg main-navbar" style={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 9999}}>
                        <button className="btn d-flex flex-column align-items-center text-light"
                                onClick={handleDecksClick}>
                            <FontAwesomeIcon className="fa-home" icon={faBoxesStacked}/>
                            Decks
                        </button>
                        <button className="btn d-flex flex-column align-items-center text-light"
                                onClick={handleIndexClick}>
                            <FontAwesomeIcon className="fa-list" icon={faList}/>
                            Index
                        </button>
                        {Cookies.get("logToken") ? (
                            <button
                                className="btn d-flex flex-column align-items-center text-light"
                                onClick={handleDashboardClick}>
                                <FontAwesomeIcon className="fa-home" icon={faHome}/>
                                Dashboard
                            </button>
                        ) : (
                            <button
                                className="btn d-flex flex-column align-items-center text-light"
                                onClick={handleLoginClick}>
                                <FontAwesomeIcon className="fa-user" icon={faUser}/>

                                Login
                            </button>
                        )}
                        <button className="btn d-flex flex-column align-items-center text-light" onClick={isBrowserView ? null : handleCounterClick}
                                disabled={isBrowserView}>
                            <MobileView>
                                <FontAwesomeIcon className="fa-coins" icon={faCoins}/><br/>
                                Counter
                            </MobileView>
                            <BrowserView>
                                <FontAwesomeIcon className="fa-coins" icon={faQuestion}/><br/>
                                Soon
                            </BrowserView>
                        </button>
                    </nav>
                </div>
            ) : (
                <VideoLauncher onVideoEnd={handleVideoEnd}/>
            )}
        </div>
    );
};

export default Main;
