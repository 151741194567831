import React, {useEffect, useRef, useState} from 'react';
import CardDetails from "../../Cardetails/CardDetails";
import Search from "../../Search/Search";
import Filter from "../../Search/Filter";
import "./Cards.css";
import { useAuth } from "../../../AuthContext";
import {Dropdown} from "react-bootstrap";
import {BrowserView, MobileView} from "react-device-detect";


const Cards = ({ cardsData, deckView, cAbilities, isCollection, toggleCardSelection, saveCollection, selectedCards, setSelectedCards, userCollection }) => {
    const [selectedCard, setSelectedCard] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCards, setFilteredCards] = useState(cardsData && cardsData.cards ? cardsData.cards : []);
    const [abilities, setAbilities] = useState(cardsData.abilities);
    const [dAbilities, setDAbilities] = useState(cAbilities);
    const { showFilter, setShowFilter, host, port, user } = useAuth();
    const [appliedFilters, setAppliedFilters] = useState({});
    const filtersParam = { "editions": cardsData.editions, "types": cardsData.types, "subtypes": cardsData.subtypes, "abilities": cardsData.abilities, "rarities": cardsData.rarities }
    const [selectedEdition, setSelectedEdition] = useState('All');
    const [filters, setFilters] = useState({});const isFilterApplied = Object.keys(filters).length > 0;
    const barRef = useRef(null);
    const [maxButtons, setMaxButtons] = useState(0);
    const scrollRight = () => {
        document.querySelector('.edition-filter').scrollLeft += 100; // Scrolls by 100px; adjust as needed
    };


    const SvgFilters = () => (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ height: 0, width: 0, position: 'absolute' }}>
            <defs>
                <filter id="shadow" height="130%">
                    <feDropShadow dx="2" dy="2" stdDeviation="2" floodColor="black" />
                </filter>
            </defs>
        </svg>
    );

    // Fonction pour réinitialiser tous les filtres
    const resetFilters = () => {
        setFilters({});
    };





    const filterByEdition = (edition) => {
        setSelectedEdition(edition);
        const filters = { ...appliedFilters, edition: [edition] };
        applyFilters(filters, searchTerm);
    };

    const filterAll = () => {
        setSelectedEdition('All');
        const filters = { ...appliedFilters };
        delete filters.edition; // Supprimer le filtre par édition
        applyFilters(filters, searchTerm);
    };

    const applyFilters = (filters, searchTerm) => {
        setAppliedFilters(filters);

        // Filtrer les cartes en fonction des filtres appliqués
        let filtered = cardsData.cards;

        if (filters.searchTerm) {
            const searchText = filters.searchTerm.toLowerCase();
            filtered = filtered.filter((card) =>
                card.name.toLowerCase().includes(searchText) ||
                card.types.some((type) => type.toLowerCase() === searchText) ||
                card.edition.some((edition) => edition.toLowerCase() === searchText) ||
                card.subtypes.some((subtype) => subtype.toLowerCase() === searchText) ||
                card.summonerSkills.some((skill) => skill.skill.toLowerCase().includes(searchText)) ||
                card.baseEffects.some((effect) => effect.effect.toLowerCase().includes(searchText)) ||
                card.baseEffects.some((effect) => effect.effectDetail.toLowerCase().includes(searchText)) ||
                card.actions.some((action) => action.tossOfCoin.some((toss) => toss.success.description.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.tossOfCoin.some((toss) => toss.success.detail.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.directPurchase.some((purchase) => purchase.action.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.directPurchase.some((purchase) => purchase.actionDetail.toLowerCase().includes(searchText)))
            );
        }

        if (filters.type && Array.isArray(filters.type)) {
            const selectedTypeTerms = filters.type.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                const cardTypeTerms = card.types.map((type) => type.trim().toLowerCase());

                return selectedTypeTerms.some((selectedTerm) => {
                    if (selectedTerm === 'All') {
                        return true; // Inclure si "All" est sélectionné
                    }

                    if (selectedTerm.includes(' ')) {
                        // Si le terme du filtre contient des espaces
                        const filterTerms = selectedTerm.split(' ');

                        if (filterTerms.every((term) => cardTypeTerms.includes(term))) {
                            return true;
                        }
                    } else {
                        if (cardTypeTerms.includes(selectedTerm)) {
                            return true;
                        }
                    }

                    return false;
                });
            });
        }

        if (filters.edition && Array.isArray(filters.edition)) {
            const selectedTypeTerms = filters.edition.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.edition.some((edition) => {
                        const typeTerms = edition.trim().toLowerCase().split(' ');
                        return typeTerms.includes(selectedTerm);
                    });
                });
            });
        }

        if (filters.subtype && Array.isArray(filters.subtype)) {
            const selectedTypeTerms = filters.subtype.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.subtypes.some((type) => {
                        const typeTerms = type.trim().toLowerCase().split(' ');
                        return typeTerms.includes(selectedTerm);
                    });
                });
            });
        }

        if (filters.ability && Array.isArray(filters.ability)) {
            const selectedTypeTerms = filters.ability.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.abilities.some((type) => {
                        const typeTerms = type.trim().toLowerCase().split(' ');
                        return typeTerms.includes(selectedTerm);
                    });
                });
            });
        }

        if (filters.defense) {
            filtered = filtered.filter((card) => filters.defense === 'All' || card.defense === filters.defense);
        }

        if (filters.healingPoints) {
            filtered = filtered.filter((card) => filters.healingPoints === 'All' || card.healingPoints === filters.healingPoints);
        }

        if (filters.rarity && Array.isArray(filters.rarity)) {
            const selectedTypeTerms = filters.rarity.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.rarity.toLowerCase() === selectedTerm
                });
            });
        }

        setFilteredCards(filtered);
    };

    const showCardDetails = (cardId) => {
        const selectedCard = filteredCards.find((card) => card._id === cardId);
        setSelectedCard(selectedCard);
    };

    const hideCardDetails = () => {
        setSelectedCard(null);
    };

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);

        const filtered = cardsData.cards.filter((card) =>
            card.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCards(filtered);
    };

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    function setActive(selectedButton) {
        document.querySelectorAll('.edition-button').forEach(button => {
            button.classList.remove('active');
        });

        selectedButton.classList.add('active');
    }

    const renderEditionButtons = (editions) => {
        const firstThreeEditions = editions.slice(0, 2);

        if (editions.length <= 2) {
            return firstThreeEditions.map((edition, index) => (
                <>
                    <button
                        className={`edition-button btn ${selectedEdition === 'All' ? 'btn-secondary' : 'btn-outline-secondary'}`}
                        onClick={filterAll}
                    >
                        All
                    </button>
                    <button
                        key={index}
                        className={`edition-button btn ${selectedEdition === edition.name ? 'btn-secondary' : 'btn-outline-secondary'}`}
                        onClick={() => filterByEdition(edition.name)}
                    >
                        {edition.name}
                    </button>
                </>
            ));
        } else {
            const otherEditions = editions.slice(2);

            return (
                <div className="d-flex justify-content-around align-items-center">
                    <button
                        className="edition-button btn active mx-1 mx-lg-4"
                        onClick={(e) => {filterAll(); setActive(e.target)}}
                    >
                        All
                    </button>
                    {firstThreeEditions.map((edition, index) => (
                        <button
                            key={index}
                            className="edition-button btn mx-1 mx-lg-4"
                            onClick={(e) => { filterByEdition(edition.name); setActive(e.target) }}
                        >
                            {edition.name}
                        </button>
                    ))}
                    <Dropdown>
                        <Dropdown.Toggle className="edition-button mx-1">
                            Prestige
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {otherEditions.map((edition, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => filterByEdition(edition.name)}
                                >
                                    {edition.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {isCollection && (
                        <button onClick={saveCollection} className="btn edition-button ms-2" >Save</button>
                    )}
                </div>
            );
        }
    };


    const calculateMaxButtons = () => {
        if (barRef.current) {
            const containerWidth = barRef.current.offsetWidth;
            let totalButtonsWidth = 0;
            let maxButtonsCount = 0;

            const buttonElements = barRef.current.querySelectorAll('.edition-button');
            buttonElements.forEach(button => {
                totalButtonsWidth += button.offsetWidth +
                    parseInt(window.getComputedStyle(button).marginLeft, 10) +
                    parseInt(window.getComputedStyle(button).marginRight, 10);
            });

            buttonElements.forEach(button => {
                const buttonWidth = button.offsetWidth +
                    parseInt(window.getComputedStyle(button).marginLeft, 10) +
                    parseInt(window.getComputedStyle(button).marginRight, 10);

                if (totalButtonsWidth <= containerWidth) {
                    maxButtonsCount = buttonElements.length;

                } else if (totalButtonsWidth - buttonWidth < containerWidth) {
                    maxButtonsCount--;
                    totalButtonsWidth -= buttonWidth;
                }
            });

            setMaxButtons(maxButtonsCount);
        }
    };

    // Call calculateMaxButtons on mount and on window resize
    useEffect(() => {
        calculateMaxButtons();
        window.addEventListener('resize', calculateMaxButtons);
        return () => window.removeEventListener('resize', calculateMaxButtons);
    }, []);



    return (
        <div className="card-component parent">
            <MobileView>
                {showFilter ? (
                    <Filter filters={filtersParam} applyFilters={applyFilters} searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}/>
                ) : (
                    <div className="container card-index">
                        <div className="top-search-bar fixed-top sticky-child bg-transparent">
                            <Search onSearch={handleSearch} toggleFilter={toggleFilter}/>
                            <div className="second-bar d-flex align-items-center mt-2">
                                <div className="edition-filter d-flex mx-auto">
                                    {deckView ===1 ?
                                        "" :
                                        cardsData.editions.map((edition, index) => (
                                            <button
                                                key={index}
                                                className="edition-button btn mx-1 mx-lg-4"
                                                onClick={(e) => { filterByEdition(edition.name); setActive(e.target) }}
                                            >
                                                {edition.name}
                                            </button>
                                        ))

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row card-list-row scrollable" style={{ paddingTop: '1rem'}}>
                            {/* Render cards based on filteredCards */}
                            {filteredCards
                                .map((card, index) => (
                                    <div key={card._id} className={"col-4 col-md-3 col-lg-2 px-1 mb-2 test"}>
                                        <div key={card._id} className="card-container pl-1 pr-1">
                                            <div style={{position: "relative", width: "fit-content", marginBottom: index === filteredCards.length - 1 ? "4em" : 0}} >
                                                <img
                                                    key={card.id}
                                                    className="card-img img-fluid mb-0 card-container"
                                                    loading="eager"
                                                    src={`/assets/cards/${(card.card && deckView === 1 ? card.card.edition[0] : card.edition)}/EN/${
                                                        (card.card && deckView === 1 ? card.card.cardNumber : card.cardNumber)
                                                    }_${card.name
                                                        .toLowerCase()
                                                        .replace(/ /g, '_')}_en_cut_100ppi_eng.png`}
                                                    alt={card.name}
                                                    onClick={() => isCollection ? toggleCardSelection(card._id) : showCardDetails(card._id)}
                                                />
                                                {selectedCards?.some((c) => c.card === card._id) && isCollection && toggleCardSelection ?
                                                    <img src={"/assets/icons/check-complete.png"} alt={"Checkmark"}
                                                         style={{
                                                             position: 'absolute',
                                                             top: '25%',
                                                             right: '25%',
                                                             width: '50%'
                                                         }}
                                                         onClick={() => toggleCardSelection(card._id)}
                                                    /> : ""
                                                }
                                            </div>
                                            {card.card && deckView === 1 && (
                                                <p className="quantity text-center"
                                                   style={{color: (card.quantity - parseInt(card.card.rank) > 0) ? 'red' : 'lightgrey'}}>{card.quantity}</p>
                                            )}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )}
            </MobileView>
            <BrowserView>
                <div className="card-component parent container-fluid browser-view">
                    <div className="row">
                        <div className="col-md-4 col-lg-3 filter"
                             style={{position: "sticky", top: "0", height: "100vh", overflowY: "auto"}}>
                            <BrowserView>
                                <h2 className="mb-3 mt-5 text-white text-center">Filter</h2>
                            </BrowserView>
                            <div className="top-search-bar bg-transparent text-center d-flex justify-content-center w-100">
                                <Search onSearch={handleSearch} toggleFilter={toggleFilter}/>
                            </div>
                            <div className="second-bar mt-2">
                                <div className="edition-filter text-center">
                                    {deckView === 1 ? ""
                                        :
                                        cardsData.editions.map((edition, index) => (
                                            <>
                                                <button
                                                    key={index}
                                                    className={`edition-button btn ${selectedEdition === edition.name ? 'btn-secondary active' : 'btn-outline-secondary'}`}
                                                    onClick={() => filterByEdition(edition.name)}
                                                >
                                                    {edition.name}
                                                </button>
                                            </>
                                        ))}
                                    <button
                                        className={`edition-button btn ${selectedEdition === 'All' ? 'btn-secondary active' : 'btn-outline-secondary'}`}
                                        onClick={filterAll}
                                    >
                                        All
                                    </button>
                                </div>
                            </div>
                            <div className="filter-section mt-3">
                                <Filter filters={filtersParam} applyFilters={applyFilters} searchTerm={searchTerm}
                                        setSearchTerm={setSearchTerm}/>
                            </div>
                        </div>
                        <div className="col-md-9 col-lg-9">
                            <div className="card-index">
                                <div className="row card-list-row scrollable" style={{paddingTop: '1rem', scrollbarWidth: "thin !important"}}>
                                    {filteredCards.map((card, index) => (
                                        <div key={card._id} className={"col-4 col-md-3 col-lg-2 px-1 mb-2 test"}>
                                            <div key={card._id} className="card-container pl-1 pr-1">
                                                <div style={{
                                                    position: "relative",
                                                    width: "fit-content",
                                                    marginBottom: index === filteredCards.length - 1 ? "4em" : 0
                                                }}>
                                                    <img
                                                        key={card.id}
                                                        className="card-img img-fluid mb-0 card-container w-100"
                                                        loading="eager"
                                                        src={`/assets/cards/${(card.card && deckView === 1 ? card.card.edition[0] : card.edition)}/EN/${
                                                            (card.card && deckView === 1 ? card.card.cardNumber : card.cardNumber)
                                                        }_${card.name
                                                            .toLowerCase()
                                                            .replace(/ /g, '_')}_en_cut_100ppi_eng.png`}
                                                        alt={card.name}
                                                        onClick={() => isCollection ? toggleCardSelection(card._id) : showCardDetails(card._id)}
                                                    />
                                                    {selectedCards?.some((c) => c.card === card._id) && isCollection && toggleCardSelection ?
                                                        <img src={"/assets/icons/check-complete.png"} alt={"Checkmark"}
                                                             style={{
                                                                 position: 'absolute',
                                                                 top: '25%',
                                                                 right: '25%',
                                                                 width: '50%'
                                                             }}
                                                             onClick={() => toggleCardSelection(card._id)}
                                                        /> : ""
                                                    }
                                                </div>
                                                {card.card && deckView === 1 && (
                                                    <p className="quantity text-center"
                                                       style={{color: (card.quantity - parseInt(card.card.rank) > 0) ? 'red' : 'lightgrey'}}>{card.quantity}</p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedCard && (
                        deckView === 1 ?
                            <CardDetails cardDetails={selectedCard.card} abilities={dAbilities}
                                         onClose={hideCardDetails}/> :
                            <CardDetails cardDetails={selectedCard} abilities={abilities} onClose={hideCardDetails}/>
                    )}
                </div>
            </BrowserView>
            {/* Render the CardDetails component when selectedCard is not null */}
            {selectedCard && (
                deckView === 1 ?
                    <CardDetails cardDetails={selectedCard.card} abilities={dAbilities} onClose={hideCardDetails}/> :
                    <CardDetails cardDetails={selectedCard} abilities={abilities} onClose={hideCardDetails}/>
            )}

        </div>
    );
};

export default Cards;