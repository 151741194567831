import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./Abilities.css";
import Ability from "../../Cardetails/Ability";
import {BrowserView, MobileView} from "react-device-detect";

const Index = ({ abilities }) => {
    const [selectedAbility, setSelectedAbility] = useState(null);
    const [showAbilityModal, setShowAbilityModal] = useState(false);

    const closeAbilityModal = () => {
        setShowAbilityModal(false);
        setSelectedAbility(null);
    };
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => console.log("Swiped left"),
        onSwipedRight: () => console.log("Swiped right"),
    });

    const SvgFilters = () => (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ height: 0, width: 0, position: 'absolute' }}>
            <defs>
                <filter id="shadow" height="130%">
                    <feDropShadow dx="2" dy="2" stdDeviation="2" floodColor="black" />
                </filter>
            </defs>
        </svg>
    );

    return (
        <div className="abilities-component parent" {...swipeHandlers}>
            <SvgFilters />
            <div className="container">
                <br/>
                <h1 className="text-white text-center">Abilities</h1>
                <hr className="text-white w-50 mx-auto"/>
                <MobileView>
                    <div className="row justify-content-center scrollable">
                        {abilities.map((ability, index) => (
                            <div key={index} className="col-3 col-md-2"
                                 style={{marginBottom: index === abilities.length - 1 ? "5em" : 0}}
                                 onClick={() => {
                                     setSelectedAbility(ability.name);
                                     setShowAbilityModal(true);
                                 }}
                            >
                                <img
                                    className="ability-icon"
                                    style={{filter: "url(#shadow)"}}
                                    src={`/assets/icons/abilities/${ability.name}.png`}
                                    alt={ability.name}
                                />
                                <p className="text-center text-white">{ability.name}</p>
                            </div>
                        ))}
                    </div>
                </MobileView>
                <BrowserView>
                    <div className="row justify-content-center scrollable">
                        {abilities.map((ability, index) => (
                            <div key={index} className="col-2 text-center"
                                 style={{marginBottom: index === abilities.length - 1 ? "5em" : 0}}
                                 onClick={() => {
                                     setSelectedAbility(ability.name);
                                     setShowAbilityModal(true);
                                 }}
                            >
                                <img
                                    className="ability-icon-browser"
                                    style={{filter: "url(#shadow)"}}
                                    src={`/assets/icons/abilities/${ability.name}.png`}
                                    alt={ability.name}
                                />
                                <p className="text-center text-white">{ability.name}</p>
                            </div>
                        ))}
                    </div>
                </BrowserView>
            </div>
            {showAbilityModal && selectedAbility && (
                <Ability ability={selectedAbility} onClose={closeAbilityModal} abilities={abilities}/>
            )}
        </div>
    );
};

export default Index;
