// UserInfos.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext'; // Assurez-vous que le chemin d'importation est correct
import './UserInfos.css';
import axios from 'axios';
import {faPlusCircle, faSignOut} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";

const UserInfos = () => {
    const { user, port, host, setUser, logout, SvgFilters } = useAuth();
    const [userInfo, setUserInfo] = useState({ username: '', email: '', password: '', thumbnail: '' });
    const [newPassword, setNewPassword] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateStatus, setUpdateStatus] = useState('');
    const [showUpdateStatus, setShowUpdateStatus] = useState(false);
    const [thumbnails, setThumbnails] = useState([]);
    const [selectedThumbnail, setSelectedThumbnail] = useState('');
    const [showThumbnails, setShowThumbnails] = useState(false)
    const [messageType, setMessageType] = useState('success'); // 'success' ou 'error'


    const toggleThumbnailSelector = () => {
        setShowThumbnails(!showThumbnails);
    };

    const handleUsernameEdit = (e) => {
        const updatedUsername = e.target.innerText; // Récupère le texte édité
        setUserInfo({...userInfo, username: updatedUsername.trim()});
    };

    useEffect(() => {
        if(user) {
            setUserInfo({ username: user.username, email: user.email, thumbnail: user.thumbnail });
            setSelectedThumbnail(user.thumbnail);
        }
    }, [user]);

    useEffect(() => {
        axios.get(`https://${host}:${port}/api/thumbnails`).then(response => {
            setThumbnails(response.data);
            console.log(response.data)
        }).catch(error => {
            console.error('Error fetching thumbnails:', error);
        });
    }, []);

    const handleLogout = async () => {
        try {
            await axios.get(`https://${host}:${port}/logout`);
            logout();  // Nettoyer l'état utilisateur
        } catch (error) {
            console.error('Erreur lors de la déconnexion:', error);
        }
    };


    const handleUpdate = async (e) => {
        e.preventDefault();
        setIsUpdating(true);

        setUserInfo(prevState => ({
            ...prevState,
            username: userInfo.username.trim(),
            email: userInfo.email.trim()
        }));

        try {
            const response = await axios.put(`https://${host}:${port}/user/update/${user._id}`, {
                username: userInfo.username,
                email: userInfo.email,
                thumbnail:userInfo.thumbnail,
                newPassword
            });

            if (response.status === 200) {
                setUpdateStatus('Informations mises à jour avec succès.');
                setMessageType('success');
                setUser(prevUser => {
                    return { ...prevUser, username: userInfo.username.trim(), email: userInfo.email.trim() , thumbnail: userInfo.thumbnail};
                });
                setNewPassword(''); // Réinitialiser le champ de mot de passe
                setShowUpdateStatus(true);
            } else {
                setUpdateStatus('Échec de la mise à jour des informations.');
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour des informations:', error);
            setUpdateStatus(error.response.data.error || 'Erreur lors de la mise à jour des informations.');
            setMessageType('error');
        }
        setIsUpdating(false);
        setShowUpdateStatus(true);
        const timer = setTimeout(() => {
            setShowUpdateStatus(false);
        }, 1500);

        // Nettoyer le timeout si le composant est démonté avant la fin
        return () => clearTimeout(timer);
    };

    const handleThumbnailSelect = (thumbnail) => {
        setSelectedThumbnail(thumbnail);
        setUserInfo({...userInfo, thumbnail});  // Met à jour userInfo avec le nouveau thumbnail
        setShowThumbnails(false);  // Ferme le sélecteur de thumbnails
    };


    return (
        <div className="user-infos-component">
            <div className="container mt-3 d-flex justify-content-center align-items-center" style={{minHeight: "80vh"}}>
                <div className="w-100" style={{maxWidth: "450px"}}> {/* Ajustement de la largeur du formulaire */}
                    <div className="text-center mb-4">
                        <h3 className="text-center mb-2">
                            <div
                                contentEditable
                                onBlur={handleUsernameEdit} // Gère la mise à jour du state
                                suppressContentEditableWarning={true} // Pour supprimer l'avertissement de React
                                style={{
                                    display: 'inline-block',
                                    cursor: 'text'
                                }}
                            >
                                {userInfo.username}
                            </div>
                        </h3>
                        <div style={{marginBottom: '1rem'}}> {/* Div pour le thumbnail */}
                            <img
                                src={`assets/icons/users/thumbnail/${selectedThumbnail}`}
                                alt="User Thumbnail"
                                className="rounded-circle img-thumbnail"
                                style={{width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer'}}
                                onClick={toggleThumbnailSelector}
                            />
                            {showThumbnails && (
                                <div className="thumbnail-selector"> {/* ... */}
                                    {thumbnails.map((thumbnail, index) => (
                                        <img
                                            key={index}
                                            src={`assets/icons/users/thumbnail/${thumbnail}`}
                                            alt={`${thumbnail}`}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                objectFit: 'cover',
                                                cursor: 'pointer'
                                            }}
                                            className="rounded-circle img-thumbnail"
                                            onClick={() => {
                                                handleThumbnailSelect(thumbnail)
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                        <div style={{marginTop: '1rem'}}>
                            <SvgFilters/>
                            <FontAwesomeIcon icon={faSignOut}
                                    title="Log Out"
                                    style={{fontSize: '1.5rem', color: 'lightgrey', cursor: 'pointer', filter: 'url(#shadow)'}}
                                    onClick={handleLogout}/>

                        </div>
                    </div>
                    <form onSubmit={handleUpdate} className="mb-3">
                        <div className="form-group mb-3">
                            <label htmlFor="email" className="form-label text-light">Email</label>
                            <input
                                type="email"
                                className="form-control border-0 border-bottom border-primary bg-transparent input-lightgray"
                                id="email"
                                value={userInfo.email}
                                onChange={(e) => setUserInfo({...userInfo, email: e.target.value})}
                                required
                            />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="newPassword" className="form-label text-light">Password ( only to update
                                Password )</label>
                            <input
                                type="password"
                                className="form-control border-0 border-bottom border-primary bg-transparent input-lightgray"
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn btn-primary w-100 mt-2">Mettre à jour</button>
                        </div>
                    </form>
                    {showUpdateStatus &&
                        <div className={`alert ${messageType === 'success' ? 'alert-info' : 'alert-danger'}`}>
                            {updateStatus}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default UserInfos;