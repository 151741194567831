import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Ability from "./Ability";
import DetailsContent from './DetailsContent';
import "./CardDetails.css"
import {BrowserView, MobileView} from "react-device-detect";

const CardDetails = ({ cardDetails, onClose, abilities }) => {
    const escapeImagePath = (path) => path.replace(/'/g, "\\'");
    const cardImagePath = `/assets/cards/${cardDetails.edition[0]}/EN/hd/${cardDetails.cardNumber}_${cardDetails.name.toLowerCase().replace(/ /g, '_')}_en.png`
    const backgroundCardImagePath = `/assets/cards/${cardDetails.edition[0]}/EN/${cardDetails.cardNumber}_${cardDetails.name.toLowerCase().replace(/ /g, '_')}_en_cut_100ppi_eng.png`
    const cardBackgroundPath = escapeImagePath(backgroundCardImagePath);
    //const rarityImagePath = `/assets/icons/rarities/${cardDetails.rarity}.png`;

    // État local pour gérer l'affichage de la modal d'aptitude
    const [showAbilityModal, setShowAbilityModal] = useState(false);
    const [showCardDetailsModal, setShowCardDetailsModal] = useState(false);
    const [showDetailsContent, setShowDetailsContent] = useState(false);
    const [selectedAbility, setSelectedAbility] = useState(null);


    // Gestionnaire d'événements pour fermer la modal d'aptitude
    const closeAbilityModal = () => {
        setShowAbilityModal(false);
        setSelectedAbility(null);
    };

    const closeDetailsModal = () => {
        setShowCardDetailsModal(false);
    };

    const showAbility = (abilityName) => {
        // Logic to show the ability details
        console.log(`Showing details for ability: ${abilityName}`);
    };

    const handleSearchIconClick = () => {
        setShowDetailsContent(!showDetailsContent);
    };

    const SvgFilters = () => (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ height: 0, width: 0, position: 'absolute' }}>
            <defs>
                <filter id="shadow" height="130%">
                    <feDropShadow dx="2" dy="2" stdDeviation="2" floodColor="black" />
                </filter>
            </defs>
        </svg>
    );


    return (
        <div className="card-details-component">
            <SvgFilters />
            <div className="modal-fullscreen text-center modal-detail justify-content-center align-items-center"
                 style={{ '--card-image-path': `url(${cardBackgroundPath})` }}>
                <div className="card-header modal-Main-custom justify-content-center align-items-center">
                    <h1 className="display-5 mb-0">{cardDetails.name}</h1>
                    {/* Types and Subtypes */}
                    <div className="mb-0">
                        {cardDetails.types
                            && cardDetails.types != "Ether"
                            && cardDetails.types != "Stock"
                            && <p className="mb-1 display-6">{cardDetails.types.join(' - ')}</p>}
                        <div className="d-flex justify-content-center">
                            {cardDetails.subtypes.length > 0 && <p className="mb-1 me-2">{cardDetails.subtypes} - </p>}
                            {cardDetails.rank
                                && cardDetails.types != "Summoner"
                                && cardDetails.types != "Ether"
                                && cardDetails.types != "Stock"
                                && <p className="mb-2">Rank: {cardDetails.rank}</p>}
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {/* Conditional content rendering */}
                    {showDetailsContent ? (
                        <DetailsContent cardDetails={cardDetails} abilities={abilities} showAbility={showAbility}
                                        onClose={() => setShowDetailsContent(false)}/>
                    ) : (
                        <>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="search-icon mx-6" onClick={handleSearchIconClick}>
                                    <i className="bi bi-search me-5"></i>
                                </div>
                                <span className="close-icon" onClick={onClose}>&times;</span>
                            </div>
                            <MobileView>
                                <img className="card-image img-fluid" src={cardImagePath} alt={cardDetails.name}/>
                            </MobileView>
                            <BrowserView>
                                <img className="card-image img-fluid w-25" src={cardImagePath} alt={cardDetails.name}/>
                            </BrowserView>
                            {/* Abilities */}
                            <MobileView>
                                <div className="row justify-content-center abilities-icons">
                                    {cardDetails.abilities.map((ability, index) => (
                                        <div key={index} className="col-3 col-md-2 mb-2">
                                            <img className="ability-icon"
                                                 style={{filter: 'url(#shadow)'}}
                                                 src={`/assets/icons/abilities/${ability}.png`} alt={ability}
                                                 onClick={() => {
                                                     setSelectedAbility(ability);
                                                     setShowAbilityModal(true);
                                                 }}/>
                                        </div>
                                    ))}
                                </div>
                            </MobileView>
                            <BrowserView>
                                <div className="row justify-content-center abilities-icons">
                                    {cardDetails.abilities.map((ability, index) => (
                                        <div key={index} className="col-3 col-md-2 mb-2">
                                            <img className="ability-icon w-25"
                                                 style={{filter: 'url(#shadow)'}}
                                                 src={`/assets/icons/abilities/${ability}.png`} alt={ability}
                                                 onClick={() => {
                                                     setSelectedAbility(ability);
                                                     setShowAbilityModal(true);
                                                 }}/>
                                        </div>
                                    ))}
                                </div>
                            </BrowserView>
                        </>
                    )}
                </div>
                {/* Ability Modal */}
                {showAbilityModal && selectedAbility && (
                    <Ability ability={selectedAbility} onClose={closeAbilityModal} abilities={abilities}/>
                )}
            </div>

        </div>
    );
};

export default CardDetails;
