import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [deckCreated, setDeckCreated] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const host = "webapp.risecardgame.com";
    const port = 3777;
    
    process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0';




    const login = (userData, token) => {
        setUser(userData);
        setUserIsLoggedIn(true);
        Cookies.set('authToken', token, { expires: 7 }); // Définir le cookie avec une expiration de 7 jours
    };

    const logout = () => {
        window.location.href="/"
        setUser(null);
        setUserIsLoggedIn(false);
        Cookies.remove('logToken');

    };

    const SvgFilters = () => (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ height: 0, width: 0, position: 'absolute' }}>
            <defs>
                <filter id="shadow" height="130%">
                    <feDropShadow dx="2" dy="2" stdDeviation="2" floodColor="black" />
                </filter>
            </defs>
        </svg>
    );


    return (
        <AuthContext.Provider value={{ host, port, userIsLoggedIn, setUserIsLoggedIn, user, setUser, deckCreated, setDeckCreated, login, logout, showFilter, setShowFilter, SvgFilters }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
