// Collection.js
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAuth} from "../../AuthContext";
import "./Collection.css"
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@mui/material";
import {Button, Dropdown} from "react-bootstrap";
import CardSelection from "../DeckBuilder/CardSelection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle, faSave} from "@fortawesome/free-solid-svg-icons";
import Search from "../Search/Search";

const Collection = ({cardsData, cardQuantitiesRef}) => {

    const [activeTab, setActiveTab] = useState('allCards');
    const [addToCollection, setAddToCollection] = useState([]);
    const [userCollection, setUserCollection] = useState([]);
    const [selectedCards, setSelectedCards] = useState([]);
    const { user, host, port } = useAuth();
    const [dialogStyle, setDialogStyle] = useState({});
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    const [currentCard, setCurrentCard] = useState(null); // Pour stocker la carte actuellement sélectionnée
    const [dialogOpen, setDialogOpen] = useState(false); // Pour contrôler l'affichage du Dialog
    const inputRefHolo = useRef(null);
    const inputRefStandard = useRef(null);
    const [hasChanged, setHasChanged] = useState(false);

    const updateCardQuantity = useCallback((cardId, type, delta) => {
        const newQuantities = { ...cardQuantitiesRef.current };

        if (!newQuantities[cardId]) {
            newQuantities[cardId] = { holo: 0, standard: 0 };
        }

        newQuantities[cardId][type] = Math.max(newQuantities[cardId][type] + delta, 0);
        cardQuantitiesRef.current = newQuantities;
    }, []);



    const increaseValueHolo = (cardId) => {
        if (inputRefHolo.current) {
            inputRefHolo.current.value = Number(inputRefHolo.current.value) + 1;
            updateCardQuantity(cardId, "holo", 1);
            inputRefHolo.current.value = cardQuantitiesRef.current[cardId]?.holo
        }
    };

    const decreaseValueHolo = (cardId) => {
        if (inputRefHolo.current) {
            inputRefHolo.current.value = Number(inputRefHolo.current.value) - 1;
            updateCardQuantity(cardId, "holo", -1);
            inputRefHolo.current.value = cardQuantitiesRef.current[cardId]?.holo
        }
    };

    const increaseValueStandard = (cardId) => {
        if (inputRefStandard.current) {
            inputRefStandard.current.value = Number(inputRefStandard.current.value) + 1;
            updateCardQuantity(cardId, "standard", 1);
            inputRefStandard.current.value = cardQuantitiesRef.current[cardId]?.standard
        }
    };

    const decreaseValueStandard = (cardId) => {
        if (inputRefStandard.current) {
            inputRefStandard.current.value = Number(inputRefStandard.current.value) - 1;
            updateCardQuantity(cardId, "standard", -1);
            inputRefStandard.current.value = cardQuantitiesRef.current[cardId]?.standard
        }
    };




    const handleDialogClose = () => {
        setDialogOpen(false);
        setCurrentCard(null);
    };

    useEffect(() => {
        if (dialogOpen && currentCard && inputRefHolo.current) {
            const cardId = currentCard._id;
            const holoValue = cardQuantitiesRef.current[cardId]?.holo;
            const standardValue = cardQuantitiesRef.current[cardId]?.standard;
            inputRefHolo.current.value = holoValue || 0;
            inputRefStandard.current.value = standardValue || 0;
        }
    }, [dialogOpen, currentCard, cardQuantitiesRef.current]);


    const handleDialogOpen = (card, cardId, event) => {
        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();

        setDialogStyle({
            position: 'absolute',
            top: `${rect.top}px`,
            left: `${rect.left}px`,
            width: `${rect.width}px`,
            height: `${rect.height}px`,
            margin: 0,
            padding: 0,
            border: 'none',
            overflow: 'visible',
            transform: 'none',
            opacity: '0.8'
        });
        setCurrentCard(card);
        setDialogOpen(true);
    };




    const saveCollection = async () => {
        try {
            // Transformation de cardQuantitiesRef en tableau attendu par l'API
            const collectionArray = Object.entries(cardQuantitiesRef.current).map(([cardId, quantities]) => ({
                card: cardId,
                quantities: quantities  // Assurez-vous que cette structure correspond à celle attendue par votre API côté serveur
            }));

            const response = await fetch(`https://${host}:${port}/user/updateCollection`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user._id, collection: collectionArray })  // Utilisez collectionArray ici
            });

            const textResponse = await response.text();  // Récupérez la réponse en tant que texte
            console.log('Raw response:', textResponse);  // Imprimez la réponse brute

            const data = JSON.parse(textResponse);  // Essayez de parser la réponse
            console.log('Parsed data:', data);  // Imprimez les données parsées

            if (data.success) {
                alert('Collection saved successfully!');
            } else {
                alert('Failed to save collection.');
            }
        } catch (error) {
            console.error('Error saving collection:', error);
        }
    };

    const cardsByEdition = cardsData.cards.reduce((acc, card) => {
        acc[card.edition] = acc[card.edition] || [];
        acc[card.edition].push(card);
        return acc;
    }, {});

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);

        const filtered = cardsData.cards.filter((card) =>
            card.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCards(filtered);
    };
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCards, setFilteredCards] = useState(cardsData && cardsData.cards ? cardsData.cards : []);
    const filterByEdition = (edition) => {
        setSelectedEdition(edition);
        const filters = { ...appliedFilters, edition: [edition] };
        applyFilters(filters, searchTerm);
    };
    const [selectedEdition, setSelectedEdition] = useState('All');
    const [appliedFilters, setAppliedFilters] = useState({});
    const applyFilters = (filters, searchTerm) => {
        setAppliedFilters(filters);

        // Filtrer les cartes en fonction des filtres appliqués
        let filtered = cardsData.cards;

        if (filters.searchTerm) {
            const searchText = filters.searchTerm.toLowerCase();
            filtered = filtered.filter((card) =>
                card.name.toLowerCase().includes(searchText) ||
                card.types.some((type) => type.toLowerCase() === searchText) ||
                card.edition.some((edition) => edition.toLowerCase() === searchText) ||
                card.subtypes.some((subtype) => subtype.toLowerCase() === searchText) ||
                card.summonerSkills.some((skill) => skill.skill.toLowerCase().includes(searchText)) ||
                card.baseEffects.some((effect) => effect.effect.toLowerCase().includes(searchText)) ||
                card.baseEffects.some((effect) => effect.effectDetail.toLowerCase().includes(searchText)) ||
                card.actions.some((action) => action.tossOfCoin.some((toss) => toss.success.description.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.tossOfCoin.some((toss) => toss.success.detail.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.directPurchase.some((purchase) => purchase.action.toLowerCase().includes(searchText))) ||
                card.actions.some((action) => action.directPurchase.some((purchase) => purchase.actionDetail.toLowerCase().includes(searchText)))
            );
        }

        if (filters.type && Array.isArray(filters.type)) {
            const selectedTypeTerms = filters.type.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                const cardTypeTerms = card.types.map((type) => type.trim().toLowerCase());

                return selectedTypeTerms.some((selectedTerm) => {
                    if (selectedTerm === 'All') {
                        return true; // Inclure si "All" est sélectionné
                    }

                    if (selectedTerm.includes(' ')) {
                        // Si le terme du filtre contient des espaces
                        const filterTerms = selectedTerm.split(' ');

                        if (filterTerms.every((term) => cardTypeTerms.includes(term))) {
                            return true;
                        }
                    } else {
                        if (cardTypeTerms.includes(selectedTerm)) {
                            return true;
                        }
                    }

                    return false;
                });
            });
        }

        if (filters.edition && Array.isArray(filters.edition)) {
            const selectedTypeTerms = filters.edition.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.edition.some((edition) => {
                        const typeTerms = edition.trim().toLowerCase().split(' ');
                        return typeTerms.includes(selectedTerm);
                    });
                });
            });
        }

        if (filters.subtype && Array.isArray(filters.subtype)) {
            const selectedTypeTerms = filters.subtype.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.subtypes.some((type) => {
                        const typeTerms = type.trim().toLowerCase().split(' ');
                        return typeTerms.includes(selectedTerm);
                    });
                });
            });
        }

        if (filters.ability && Array.isArray(filters.ability)) {
            const selectedTypeTerms = filters.ability.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.abilities.some((type) => {
                        const typeTerms = type.trim().toLowerCase().split(' ');
                        return typeTerms.includes(selectedTerm);
                    });
                });
            });
        }

        if (filters.defense) {
            filtered = filtered.filter((card) => filters.defense === 'All' || card.defense === filters.defense);
        }

        if (filters.healingPoints) {
            filtered = filtered.filter((card) => filters.healingPoints === 'All' || card.healingPoints === filters.healingPoints);
        }

        if (filters.rarity && Array.isArray(filters.rarity)) {
            const selectedTypeTerms = filters.rarity.map((term) => term.trim().toLowerCase());

            filtered = filtered.filter((card) => {
                return selectedTypeTerms.some((selectedTerm) => {
                    return selectedTerm === 'All' || card.rarity.toLowerCase() === selectedTerm
                });
            });
        }

        setFilteredCards(filtered);
    };
    function setActive(selectedButton) {
        document.querySelectorAll('.edition-button').forEach(button => {
            button.classList.remove('active');
        });

        selectedButton.classList.add('active');
    }

    return(
        <div className="collection-component parent">
            <div className="top-search-bar fixed-top sticky-child bg-transparent mx-auto">
                <Search onSearch={handleSearch}/>
                <div className="second-bar d-flex align-items-center mt-2">
                    <div className="edition-filter d-flex mx-auto">
                        {cardsData.editions.map((edition, index) => (
                            <button
                                key={index}
                                className="edition-button btn mx-1 mx-lg-4"
                                onClick={(e) => {
                                    filterByEdition(edition.name);
                                    setActive(e.target)
                                }}
                            >
                                {edition.name}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row card-list-row scrollable" style={{paddingTop: '1rem'}}>
                {/* Render cards based on filteredCards */}
                {filteredCards.map((card) => (
                    <div key={card._id} className={`col-4 col-md-3 col-lg-2 px-1 mb-2 test`}
                         onClick={(e) => handleDialogOpen(card, card._id, e)}>
                        <div key={card._id} className="card-container pl-1 pr-1">
                            <div style={{position: "relative", width: "fit-content"}}>
                                <img
                                    key={card.id}
                                    className="card-img img-fluid mb-0 card-container"
                                    loading="eager"
                                    src={`/assets/cards/${card.edition[0]}/EN/${card.cardNumber}_${card.name
                                        .toLowerCase()
                                        .replace(/ /g, '_')}_en_cut_100ppi_eng.png`}
                                    alt={card.name}
                                />
                                <img
                                    src={
                                        cardQuantitiesRef.current[card._id]?.holo === 0 && cardQuantitiesRef.current[card._id]?.standard === 0 ? "" :
                                            cardQuantitiesRef.current[card._id]?.holo > 0 && cardQuantitiesRef.current[card._id]?.standard > 0 ? "/assets/icons/check-complete.png" :
                                                cardQuantitiesRef.current[card._id]?.holo > 0 ? "/assets/icons/check-holo.png" :
                                                    cardQuantitiesRef.current[card._id]?.standard > 0 ? "/assets/icons/check-standard.png" : ""
                                    }
                                    alt={
                                        cardQuantitiesRef.current[card._id]?.holo === 0 && cardQuantitiesRef.current[card._id]?.standard === 0 ? "" :
                                            cardQuantitiesRef.current[card._id]?.holo > 0 && cardQuantitiesRef.current[card._id]?.standard > 0 ? "Check Complete" :
                                                cardQuantitiesRef.current[card._id]?.holo > 0 ? "Check Holo" :
                                                    cardQuantitiesRef.current[card._id]?.standard > 0 ? "Check Standard" : ""
                                    }
                                    style={{
                                        position: 'absolute',
                                        top: '0%',
                                        right: '0%',
                                        width: '100%'
                                    }}
                                />
                            </div>
                            {card && (
                                <p className="quantity text-center"
                                   style={{color: (card.quantity - parseInt(card.rank) > 0) ? 'red' : 'lightgrey'}}>{card.quantity}</p>
                            )}
                        </div>
                    </div>
                ))}
                <Dialog key={Math.random()} open={dialogOpen} onClose={handleDialogClose}
                        PaperProps={{style: dialogStyle,}} TransitionComponent={Transition}>
                    <DialogContent style={{padding: '0 4px'}}>
                        {currentCard && (
                            <div className="text-center">
                                <div className="text-center">Holo</div>
                                <div className="d-flex justify-content-around mb-1">
                                    <FontAwesomeIcon
                                        icon={faMinusCircle}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '1.5rem',
                                            color: 'tan'
                                        }}
                                        onClick={(e) => decreaseValueHolo(currentCard._id)}
                                        type="button"
                                    />
                                    <input
                                        className="w-50 text-center"
                                        style={{
                                            border: "none",
                                            outline: "none"
                                        }}
                                        readOnly
                                        type="number"
                                        placeholder={cardQuantitiesRef.current[currentCard._id]?.holo || "0"}
                                        ref={inputRefHolo}
                                    />
                                    <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '1.5rem',
                                            color: 'tan'
                                        }}
                                        onClick={(e) => increaseValueHolo(currentCard._id)}
                                        type="button"
                                    />
                                </div>

                                <div className="text-center">Standard</div>
                                <div className="d-flex justify-content-around align-content-center mb-2">
                                    <FontAwesomeIcon
                                        icon={faMinusCircle}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '1.5rem',
                                            color: 'tan'
                                        }}
                                        onClick={(e) => decreaseValueStandard(currentCard._id)}
                                        type="button"
                                    />
                                    <input
                                        className="w-50 text-center"
                                        style={{
                                            border: "none",
                                            outline: "none"
                                        }}
                                        readOnly
                                        type="number"
                                        placeholder={cardQuantitiesRef.current[currentCard._id]?.standard || "0"}
                                        ref={inputRefStandard}
                                    />
                                    <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '1.5rem',
                                            color: 'tan'
                                        }}
                                        onClick={(e) => increaseValueStandard(currentCard._id)}
                                        type="button"
                                    />
                                </div>
                                <FontAwesomeIcon
                                    icon={faSave}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '2rem',
                                        color: 'tan'
                                    }}
                                    onClick={(e) => {
                                        saveCollection();
                                        handleDialogClose();
                                    }}
                                />
                            </div>
                        )}
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
};

export default Collection;
