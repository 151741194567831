import React, {useEffect, useRef, useState} from 'react';
import DeckList from './DeckList';
import Collection from './Collection';
import UserInfos from './UserInfos';
import "./Dashboard.css";
import {useAuth} from "../../AuthContext";
import {useSwipeable} from "react-swipeable";
import PublicDeckList from "./PublicDeckList";

function setActive(selectedButton) {
    document.querySelectorAll('.tab-button').forEach(button => {
        button.classList.remove('active');
    });

    selectedButton.classList.add('active');
}

const Dashboard = ({ cardsData, gameData, publicDecks }) => {
    const { user, host, port } = useAuth();
    const [activeTab, setActiveTab] = useState('deckList');
    const [showPublicDecks, setShowPublicDecks] = useState(true);
    const [deckListKey, setDeckListKey] = useState(0); // Nouvel état pour la clé de réinitialisation
    const cardQuantitiesRef = useRef({});

    const handleDeckListClick = (e) => {
        setActiveTab('deckList');
        setActive(e.target);
        setDeckListKey(prevKey => prevKey + 1); // Change la clé pour forcer le rechargement
    };

    const getCollection = async () => {
        try {
            const response = await fetch(`https://${host}:${port}/user/${user._id}/getCollection`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            const newQuantities = {};
            data.collection.forEach(item => {
                newQuantities[item.card._id] = {
                    holo: item.quantities.holo || 0,
                    standard: item.quantities.standard || 0,
                };
            });

            cardQuantitiesRef.current = newQuantities;
        } catch (error) {
            // Gérer les erreurs de réseau
            console.error('Network error:', error);
        }
    };

    useEffect(() => {
        getCollection();
    }, []);

    const MIN_SWIPE_DISTANCE = 100;

    const swipeHandlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            if (Math.abs(eventData.deltaX) > MIN_SWIPE_DISTANCE) {
                setShowPublicDecks(!showPublicDecks);
            }
        },
        onSwipedRight: (eventData) => {
            if (Math.abs(eventData.deltaX) > MIN_SWIPE_DISTANCE) {
                setShowPublicDecks(!showPublicDecks);
            }
        },
    });

    return (
        <div className="dashboard-component parent">
            <div className="dashboard-nav fixed-top pt-2">
                <div className="container">
                    <div className="row">
                        <div className="col-4 d-flex justify-content-center align-items-center my-2">
                            <button type="button" className="btn tab-button active text-white"
                                    onClick={handleDeckListClick}>
                                Deck List
                            </button>
                        </div>
                        <div className="col-4 d-flex justify-content-center align-items-center my-2">
                            <button type="button" className="btn tab-button text-white">
                                ? Soon ?
                            </button>
                        </div>
                        <div className="col-4 d-flex justify-content-center align-items-center my-2">
                            <button type="button" className="btn tab-button text-white" onClick={(e) => {
                                setActiveTab('userInfos');
                                setActive(e.target);
                            }}>
                                Infos
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container dashboard-content">
                <div className="row">
                    <div className="card mt-5 bg-transparent">

                        <div className="tab-content">
                            {activeTab === 'deckList' &&
                                <div {...swipeHandlers}>
                                    {showPublicDecks ? (
                                        <DeckList key={deckListKey} cardsData={cardsData} gameData={gameData}/>
                                    ) : (
                                        <PublicDeckList key={deckListKey} cardsData={cardsData} gameData={gameData}
                                                        publicDecks={publicDecks}/>
                                    )}
                                </div>}
                            {activeTab === 'collection' &&
                                <Collection cardsData={cardsData} setActive={setActive}
                                            cardQuantitiesRef={cardQuantitiesRef}/>}
                            {activeTab === 'userInfos' && <UserInfos/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
